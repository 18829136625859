<script>
export default {
  data() {
    return {
      head_icon: 'anchor',
      show_source_form: false,
      source_table: {
        per_page: 5,
        cur_page: 1,
      },
      source_data: {
        source_id: null,
        name: '',
        slug: '',
        data_value_json: '',
        ingest_interval: null,
        pull_interval: null,
        status: 1,
        device: {
          device_id: null,
          signal_source: {
            signal_source_id: null,
          },
        },
        signal_source: {
          signal_source_id: null,
        },
      },
      source_device_id: this.$route.params.device_id || '',
      modal_json: {},
      config_json: {},
    }
  },
  methods: {
    toggleSourceForm() {
      this.clearSourceForm()
      this.show_source_form = !this.show_source_form
    },
    clearSourceForm() {
      this.source_data = {
        source_id: null,
        name: '',
        slug: '',
        data_value_json: '',
        ingest_interval: null,
        pull_interval: null,
        status: 1,
        device: {
          device_id: null,
          signal_source: {
            signal_source_id: null,
          },
        },
        signal_source: {
          signal_source_id: null,
        },
      }
    },
    //--
    submitSource() {
      this.$store.dispatch('queSource', this.source_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_source_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getSource')
        }
      })
      this.clearSourceForm()
    },
    deleteSource(_source) {
      this.$bvModal
        .msgBoxConfirm(this.ln['msg_confirm'], {
          ...this.$store.state.modal_opt,
          title: this.ln['confirm_title'],
          okTitle: this.ln['btn_ok'],
          cancelTitle: this.ln['btn_cancel'],
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('delSource', _source).then((res) => {
              if (res && res.err) {
                this.showMsg(this.ln['msg_error'], 'danger')
              } else {
                this.showMsg(this.ln['msg_success'])
                this.$store.commit('getSource')
              }
            })
          }
        })
    },
    editSource(_source) {
      this.clearSourceForm()
      this.source_data = _source
      if (this.source_data.signal_source == null) {
        this.source_data.signal_source = { signal_source_id: null }
      }
      this.show_source_form = true

      this.changeConfigurationSignalSource(false)
    },
    changeSourceDevice() {
      this.source_data.signal_source.signal_source_id = null
      this.config_json = {}
      this.modal_json = {}
      this.changeConfigurationSignalSource()
    },
    changeConfigurationSignalSource(_change) {
      _change = _change == undefined ? true : _change
      this.config_json = {}
      this.modal_json = {}
      let _signal_source = null

      if (this.source_data.signal_source.signal_source_id) {
        _signal_source = this.arr_signalsource.find((source) => {
          return source.signal_source_id == this.source_data.signal_source.signal_source_id
        })
      }

      if (_signal_source) {
        _signal_source.signal_source_configs.map((e) => {
          this.config_json[e.slug] = ''

          this.modal_json[e.slug] = ''
          if (e.type) {
            let _json_select = this.$store.state.signalsource.find((source) => {
              return source.signal_source_id == e.type
            })

            this.modal_json[e.slug] = _json_select.signal_source_configs
          }
        })
      }

      if (_change) {
        let json_str = ''
        if (Object.keys(this.config_json).length > 0) {
          json_str = JSON.stringify(this.config_json)
        }
        this.source_data.data_value_json = json_str
      }
    },
    getConfigurationJsonString() {
      //console.log(this.config_json);
      this.source_data.data_value_json = JSON.stringify(this.config_json)
    },
    copyClipboard(text) {
      navigator.clipboard.writeText(text)
    },
  },
  filters: {
    pretty: function(value) {
      if (value) {
        return JSON.stringify(JSON.parse(value), null, 2)
      }
    },
  },
  computed: {
    arr_signalsource() {
      let ret = []
      if (this.source_data.device.device_id) {
        let device = this.$store.state.device.find((_device) => {
          return _device.device_id == this.source_data.device.device_id
        })
        if (device.signal_source) {
          ret = this.$store.state.signalsource.filter((e) => {
            return e.parent == device.signal_source.signal_source_id
          })
        }
      }
      return ret || []
    },
    source_device() {
      let ret = null
      if (this.source_device_id && this.$store.state.device) {
        ret = this.$store.state.device.find((e) => {
          return e.device_id == this.source_device_id
        })
      }
      return ret
    },
    arr_source() {
      let ret = []
      if (this.$store.state.source) {
        ret = this.$store.state.source
        if (this.source_device_id) {
          ret = this.$store.state.source.filter((e) => {
            return e.device.device_id == this.source_device_id
          })
        }
      }
      return ret
    },
    source_len() {
      return this.arr_source.length || 0
    },
    show_source_pager() {
      return this.source_len > this.source_table.per_page
    },
    source_edit() {
      return this.source_data.source_id ? 1 : 0
    },
  },
  mounted() {
    this.$store.commit('getSignalSource')
    this.$store.commit('getDevice')
    this.$store.commit('getSource')
  },
}
</script>

<template>
  <main class="content">
    <div
      :class="'pricing-header px-3 pb-3 pb-md-5 mx-auto text-center ' + (arr_source.length > 0 ? 'mini' : '')"
    >
      <fa-icons class="picon mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln['sources_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['sources_desc'] }}</small>
      </p>
      <button class="btn btn-dark" @click="toggleSourceForm" :disabled="show_source_form">
        <fa-icons icon="plus" />
        {{ ln['btn_addnew'] }}
      </button>
    </div>

    <div>
      <div class="row">
        <div class="col-12 col-xl">
          <section class="widget pb-0">
            <header>
              <h4>
                <b>{{ source_device ? source_device.name + ' - ' : '' }}</b>
                {{ ln['source_list'] }}
              </h4>
              <div class="widget-controls full">
                <b-form inline>
                  <label class="mr-sm-2 font-weight-bold">{{ ln['device'] }} :</label>
                  <b-form-select
                    size="sm"
                    v-model="source_device_id"
                    @change="
                      (value) => {
                        $router.push({ path: '/source/' + value })
                      }
                    "
                    :options="$store.state.device"
                    value-field="device_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option value>{{ ln['form_showall'] }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form>
              </div>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                striped
                :items="arr_source"
                :fields="[
                  /*
                  {
                    key: 'source_id',
                    label: ln['form_id'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  */
                  {
                    key: 'name',
                    label: ln['form_sourcename'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  {
                    key: 'device',
                    label: ln['device'],
                  },
                  {
                    key: 'slug',
                    label: `${ln['form_sourcekey']}`,
                  },
                  /*
                  {
                    key: 'ingest_interval',
                    label: ln['form_ingestinterval'],
                  },
                  {
                    key: 'pull_interval',
                    label: ln['form_pullinterval'],
                  },
                  */
                  /*
                  {
                    key: 'data_value_json',
                    label: ln['form_configjson'],
                  },
                  */
                  {
                    key: 'status',
                    label: ln['form_status'],
                    class: 'text-center',
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
                :per-page="source_table.per_page"
                :current-page="source_table.cur_page"
              >
                <template v-slot:cell(device)="row">{{ row.item.device.name }}</template>

                <template v-slot:cell(slug)="row">
                  {{ row.item.slug.slice(0, 200) }}
                  <b-button
                    v-if="false"
                    class="ml-2"
                    size="xs"
                    variant="info"
                    @click="copyClipboard(row.item.slug)"
                  >
                    <fa-icons icon="copy" /> Copy
                  </b-button>
                </template>

                <template v-slot:cell(status)="row">
                  <b-button
                    size="xs"
                    :variant="row.item.status ? 'success' : 'danger'"
                    class="px-2 font-weight-normal"
                    @click="$store.dispatch('toggleSourceStatus', row.item)"
                  >
                    {{ ln['form_' + (row.item.status ? 'active' : 'passive')] }}
                  </b-button>
                </template>

                <template v-slot:cell(action)="row">
                  <b-button size="xs" variant="info" @click="editSource(row.item)">
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button size="xs" variant="danger" class="ml-2" @click="deleteSource(row.item)">
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

            <footer class="pb-1">
              <b-pagination
                v-show="show_source_pager"
                v-model="source_table.cur_page"
                :total-rows="source_len"
                :per-page="source_table.per_page"
                align="center"
              ></b-pagination>
              <p class="text-center text-info" v-show="!source_len">
                {{ ln['msg_empty'] }}
              </p>
            </footer>
          </section>
        </div>

        <div class="col-12 col-xl-12 order-first order-xl-first" v-if="show_source_form">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['source_' + (source_edit ? 'edit' : 'new')] }}
              </h4>
              <div class="widget-controls">
                <a href @click.prevent="toggleSourceForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitSource()">
                <b-form-group :label="ln['device']">
                  <b-form-select
                    v-model="source_data.device.device_id"
                    :options="$store.state.device"
                    value-field="device_id"
                    text-field="name"
                    required
                    @change="changeSourceDevice()"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        {{ ln['form_selectone'] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_sourcename']">
                  <b-form-input
                    v-model="source_data.name"
                    required
                    placeholder="e.g Signal name"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_sourcekey']">
                  <b-form-input v-model="source_data.slug" placeholder="e.g Signal key"></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_signalsource']">
                  <b-form-select
                    v-model="source_data.signal_source.signal_source_id"
                    :options="arr_signalsource"
                    value-field="signal_source_id"
                    text-field="name"
                    @change="changeConfigurationSignalSource()"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" selected>
                        {{ ln['form_customtype'] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="`${ln['form_ingestinterval']} (${ln['form_milisecond']})`">
                  <b-form-input v-model="source_data.ingest_interval"></b-form-input>
                </b-form-group>

                <b-form-group :label="`${ln['form_pullinterval']} (${ln['form_milisecond']})`">
                  <b-form-input v-model="source_data.pull_interval"></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_configjson']">
                  <div class="row">
                    <div class="col-sm-10">
                      <b-form-input
                        v-show="false"
                        readonly
                        v-model="source_data.data_value_json"
                        class="txtjson"
                      ></b-form-input>

                      <b-card class="mt-0 bg-muted">
                        <pre class="m-0 text-muted">{{ source_data.data_value_json | pretty }}</pre>
                      </b-card>
                    </div>

                    <div class="col-sm-2 mt-2 mt-sm-0 px-sm-0">
                      <b-button
                        v-b-modal.modal-1
                        size="sm"
                        variant="info"
                        :disabled="Object.keys(this.config_json).length > 0 ? false : true"
                      >
                        <fa-icons icon="life-ring" class="mr-1" />
                        JSON Helper
                      </b-button>

                      <b-modal id="modal-1" title="BootstrapVue" @ok="getConfigurationJsonString()">
                        <b-form>
                          <b-form-group :label="i" v-for="(field, i) in this.modal_json" :key="i">
                            <b-form-input v-model="config_json[i]" v-if="field.length == 0"></b-form-input>

                            <b-form-select
                              v-model="config_json[i]"
                              v-if="field.length > 0"
                              :options="field"
                              value-field="slug"
                              text-field="name"
                              required
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null" disabled>
                                  - {{ ln['form_selectone'] }}
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-modal>
                    </div>
                  </div>
                </b-form-group>

                <b-button type="submit" variant="dark">
                  {{ ln['btn_' + (source_edit ? 'update' : 'create')] }}
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
