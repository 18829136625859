<script>
export default {
  data() {
    return {
      head_icon: 'briefcase',
      show_company_form: false,
      company_table: {
        per_page: 5,
        cur_page: 1,
      },
      company_data: {
        company_id: null,
        name: '',
        icode: '',
        status: 1,
        country: {
          country_id: null,
        },
      },
    }
  },
  methods: {
    toggleCompanyForm() {
      this.clearCompanyForm()
      this.show_company_form = !this.show_company_form
    },
    clearCompanyForm() {
      this.company_data = {
        company_id: null,
        name: '',
        icode: '',
        status: 1,
        country: {
          country_id: null,
        },
      }
    },
    //--
    submitCompany() {
      this.$store.dispatch('queCompany', this.company_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_company_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getCompany')
        }
      })
      this.clearCompanyForm()
    },
    deleteCompany(_company) {
      this.$bvModal
        .msgBoxConfirm(this.ln['msg_confirm'], {
          ...this.$store.state.modal_opt,
          title: this.ln['confirm_title'],
          okTitle: this.ln['btn_ok'],
          cancelTitle: this.ln['btn_cancel'],
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('delCompany', _company).then((res) => {
              if (res && res.err) {
                this.showMsg(this.ln['msg_error'], 'danger')
              } else {
                this.showMsg(this.ln['msg_success'])
                this.$store.commit('getCompany')
              }
            })
          }
        })
    },
    editCompany(_company) {
      this.clearCompanyForm()
      this.company_data = _company
      this.show_company_form = true
    },
  },
  computed: {
    arr_company() {
      return this.$store.state.company || []
    },
    arr_country() {
      return this.$store.state.country || []
    },
    company_len() {
      return this.arr_company.length || 0
    },
    show_company_pager() {
      return this.company_len > this.per_page
    },
    company_edit() {
      return this.company_data.company_id ? 1 : 0
    },
  },
  mounted() {
    this.$store.commit('getCountry')
    this.$store.commit('getCompany')
  },
}
</script>

<template>
  <main class="content">
    <div
      :class="
        'pricing-header px-3 pb-3 pb-md-5 mx-auto text-center ' + (arr_company.length > 0 ? 'mini' : '')
      "
    >
      <fa-icons class="picon mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln['companies_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['companies_desc'] }}</small>
      </p>
      <button class="btn btn-dark" @click="toggleCompanyForm" :disabled="show_company_form">
        <fa-icons icon="plus" />
        {{ ln['btn_addnew'] }}
      </button>
    </div>

    <div>
      <div class="row">
        <div class="col-12 col-xl">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['company_list'] }}
              </h4>
              <div class="widget-controls"></div>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                striped
                :items="arr_company"
                :fields="[
                  {
                    key: 'name',
                    label: ln['form_companyname'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  {
                    key: 'country',
                    label: ln['form_country'],
                  },
                  {
                    key: 'locations',
                    label: ln['locations_title'],
                    class: 'text-center',
                  },
                  {
                    key: 'status',
                    label: ln['form_status'],
                    class: 'text-center',
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
                :per-page="company_table.per_page"
                :current-page="company_table.cur_page"
              >
                <template v-slot:cell(country)="row">{{ row.item.country.name }}</template>

                <template v-slot:cell(locations)="row">
                  <router-link
                    class="btn btn-xs font-weight-bold bg-warning"
                    :to="`/location/${row.item.company_id}`"
                  >
                    {{ row.item.locations.length }} {{ ln['location'] }}
                  </router-link>
                </template>

                <template v-slot:cell(status)="row">
                  <b-button
                    size="xs"
                    :variant="row.item.status ? 'success' : 'danger'"
                    class="px-2 font-weight-normal"
                    @click="$store.dispatch('toggleCompanyStatus', row.item)"
                  >
                    {{ ln['form_' + (row.item.status ? 'active' : 'passive')] }}
                  </b-button>
                </template>

                <template v-slot:cell(action)="row">
                  <b-button size="xs" variant="info" @click="editCompany(row.item)">
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button size="xs" variant="danger" class="ml-2" @click="deleteCompany(row.item)">
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

            <footer class="pb-1">
              <b-pagination
                v-show="show_company_pager"
                v-model="company_table.cur_page"
                :total-rows="company_len"
                :per-page="company_table.per_page"
                align="center"
              ></b-pagination>
              <p class="text-center text-info" v-show="!company_len">
                {{ ln['msg_empty'] }}
              </p>
            </footer>
          </section>
        </div>

        <div class="col-12 col-xl-4 order-first order-xl-last" v-if="show_company_form">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['company_' + (company_edit ? 'edit' : 'new')] }}
              </h4>
              <div class="widget-controls">
                <a href @click.prevent="toggleCompanyForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitCompany()">
                <b-form-group :label="ln['form_companyname']">
                  <b-form-input
                    v-model="company_data.name"
                    required
                    placeholder="e.g TrexDCAS"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_country']">
                  <b-form-select
                    v-model="company_data.country.country_id"
                    :options="arr_country"
                    value-field="country_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        - {{ ln['form_selectone'] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_induscode']">
                  <b-form-input v-model="company_data.icode" required placeholder="e.g 555111"></b-form-input>
                </b-form-group>

                <b-button type="submit" variant="dark">
                  {{ ln['btn_' + (company_edit ? 'update' : 'create')] }}
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
