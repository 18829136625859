<script>
export default {
  props: ['watched_source', 'interval'],
  components: {},
  data() {
    return {
      watch_timer: null,
      watch_data: {
        data: [
          {
            key: '',
            value: '',
            time: '',
          },
        ],
      },
      watched_config: '',
      chart_data: null,
    }
  },
  methods: {
    fillChart() {
      let _datasets = []
      let _colors = ['#f44336', '#17a2b8', '#4caf50', '#673ab7', '#fbb031', '#795548', '#607d8b']
      this.watch_data.forEach((sensor, i) => {
        console.log(sensor)
        if (sensor.values && sensor.values.length > 0) {
          let _data = sensor.values.map((data) => {
            return { x: JSON.parse(data).time, y: JSON.parse(data).value }
          })

          _datasets.push({
            label: sensor.key,
            borderColor: _colors[i],
            data: _data,
          })
        }
      })

      this.chart_data = _datasets
    },
    watchHandler(_monitore) {
      /*
      this.$store.dispatch('monitorValue', _monitore).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res);
          this.watch_data = res.data[0].values
          this.fillChart()
        }
      })
      */
      let req = JSON.parse(_monitore.data_value_json)
      this.$store.dispatch('getKeyValue', req).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res.data)
          this.watch_data = res.data
          this.fillChart()
        }
      })
    },
    beginWatch(_monitore) {
      this.endWatch()
      /*
      let _interval = _monitore.source.device.gateway.pull_interval
      if (_monitore.source.pull_interval) {
        _interval = _monitore.source.pull_interval
      } else if (_monitore.source.device.pull_interval) {
        _interval = _monitore.source.device.pull_interval
      }
      */

      let _interval = this.interval

      console.log(`watching every ${_interval} ms`)

      this.watchHandler(_monitore)
      if (_interval > 0) {
        this.watch_timer = setInterval(() => {
          this.watchHandler(_monitore)
        }, _interval)
      }
    },
    endWatch() {
      console.log(`End watching..`)
      clearInterval(this.watch_timer)
      this.watch_timer = null
    },
  },
  watch: {
    //eslint-disable-next-line
    watched_source: function(newVal, oldVal) {
      this.watch_data = newVal
      this.fillChart()
    },
    interval: function() {
      this.beginWatch(this.watched_source)
    },
  },
  mounted() {
    //console.log(this.watched_source)
    if (this.watched_source.data_value_json) {
      this.watched_config = JSON.parse(this.watched_source.data_value_json)
    }
    this.beginWatch(this.watched_source)
    //this.watch_data = this.watched_source
    //this.fillChart()
  },
  computed: {
    statusIcon() {
      let ret = 'arrows-alt-h'
      if (this.chart_data) {
        if (this.chart_data[1].value && this.chart_data[0].value > this.chart_data[1].value) {
          ret = 'arrow-up'
        } else if (this.chart_data[1].value && this.chart_data[0].value < this.chart_data[1].value) {
          ret = 'arrow-down'
        }
      }
      return ret
    },
    datablocks() {
      return this.chart_data.data ? this.chart_data : []
    },
  },
  destroyed() {
    this.endWatch()
  },
}
</script>

<template>
  <div class="row no-gutters justify-content-center">
    <div class="col-md-* p-2" v-for="sensor in this.chart_data" :key="sensor.label">
      <div class="border rounded pt-3 px-3 pb-2 text-center">
        <h6 class="mb-2">
          {{ sensor.data ? sensor.label : '-' }}
        </h6>
        <h1>
          <b-badge variant="warning" class="py-2" :style="`background-color: ${sensor.borderColor};`">
            {{ sensor.data && sensor.data[0].y.toFixed(2) }}
            <fa-icons :icon="`arrow-up`" class="ml-2" />
          </b-badge>
        </h1>
        <h6 class="mt-3">
          <small>
            <fa-icons icon="clock" />
            {{ sensor.data ? frmDate(sensor.data[0].x) : '-' }}
          </small>
        </h6>
      </div>
    </div>
  </div>
</template>
