<script>
import WidgetDefault2 from '@/components/WidgetDefault2'
import WidgetOnoff2 from '@/components/WidgetOnoff2'
import WidgetDonut2 from '@/components/WidgetDonut2'
import WidgetProgress2 from '@/components/WidgetProgress2'
import WidgetLine3 from '@/components/WidgetLine3'
import WidgetPercentage2 from '@/components/WidgetPercentage2'
import WidgetBars2 from '@/components/WidgetBars2'
import WidgetLiquid2 from '@/components/WidgetLiquid2'
import WidgetSpeed from '@/components/WidgetSpeed'

export default {
  components: {
    WidgetDefault2, //eslint-disable-line
    WidgetOnoff2, //eslint-disable-line
    WidgetDonut2, //eslint-disable-line
    WidgetProgress2, //eslint-disable-line
    WidgetLine3, //eslint-disable-line
    WidgetPercentage2, //eslint-disable-line
    WidgetBars2, //eslint-disable-line
    WidgetLiquid2, //eslint-disable-line
    WidgetSpeed, //eslint-disable-line
  },
  data() {
    return {
      dashboard_data: {
        dashboard_id: null,
        name: '',
        slug: '',
      },
      refresh: 0,
      design_dashboard_id: this.$route.params.dashboard_id || '',
    }
  },
  methods: {
    closeDesign() {},
    async run_query(req) {
      return await this.$store.dispatch('getKeyValue', JSON.parse(req)).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          console.log(res.data)
          return res.data
          //this.watch_data = res.data[0].values
          //this.fillChart()
        }
      })
    },
  },
  computed: {
    refresh_ms() {
      return this.refresh * 1000
    },
    arr_dashboard() {
      return this.$store.state.dashboard.find(
        (_dashboard) => _dashboard.dashboard_id == this.design_dashboard_id
      )
    },
    arr_pinned() {
      let ret = []
      if (this.$store.state.monitore) {
        ret = this.$store.state.monitore.filter(
          (_monitore) =>
            _monitore.pin == 1 &&
            _monitore.dashboard &&
            _monitore.dashboard.dashboard_id == this.design_dashboard_id
        )
      }
      return ret
    },
  },
  mounted() {
    this.$store.commit('getChart')
    this.$store.commit('getDevice')
    this.$store.commit('getMonitore')
  },
  destroyed() {},
}
</script>

<template>
  <main class="content">
    <div class="row">
      <div class="col-12 col-xl">
        <section class="widget mb-0">
          <header class="bg-warning">
            <h5 class="font-weight-bold">{{ arr_dashboard.name }}</h5>
            <div class="widget-controls">
              <router-link class="text-dark" to="/dashboard">
                <fa-icons icon="times" />
              </router-link>
            </div>
          </header>
          <div class="widget-body pt-4 mx-auto text-center">
            <div class="d-flex justify-content-end mb-4">
              <b-form-select class="col-md-3" plain v-model="refresh" :options="[2, 5, 10]">
                <template v-slot:first>
                  <b-form-select-option :value="0">
                    No refresh
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="grid-layout">
              <b-card
                :title="pin.name"
                :class="`grid-item span-${pin.size}`"
                v-for="(pin, i) in arr_pinned"
                :key="`dash_${design_dashboard_id}_pin_${i}`"
              >
                <!-- 1: on/off -->
                <div v-if="pin.chart && pin.chart.chart_id == 1" class="pindiv">
                  <WidgetOnoff2 :watched_source="pin" :interval="refresh_ms" />
                </div>

                <!-- 2: donut -->
                <div v-if="pin.chart && pin.chart.chart_id == 2" class="pindiv">
                  <WidgetDonut2 :watched_source="pin" :interval="refresh_ms" />
                </div>

                <!-- 3: progress -->
                <div v-if="pin.chart && pin.chart.chart_id == 3" class="pindiv">
                  <WidgetProgress2 :watched_source="pin" :interval="refresh_ms" />
                </div>

                <!-- 4: line -->
                <div v-if="pin.chart && pin.chart.chart_id == 4" class="pindiv">
                  <WidgetLine3 :watched_source="pin" :interval="refresh_ms" />
                </div>

                <!-- 5: perc
                <div v-if="pin.chart && pin.chart.chart_id == 5" class="pindiv">
                  <WidgetPercentage2 :watched_source="pin" :interval="refresh_ms" />
                </div> -->

                <!-- 6: bar -->
                <div v-if="pin.chart && pin.chart.chart_id == 6" class="pindiv">
                  <WidgetBars2 :watched_source="pin" :interval="refresh_ms" />
                </div>

                <!-- 7: liquid
                <div v-if="pin.chart && pin.chart.chart_id == 7" class="pindiv">
                  <WidgetLiquid2 :watched_source="pin" :interval="refresh_ms" />
                </div> -->

                <!-- 7: speed
                <div v-if="pin.chart && pin.chart.chart_id == 8" class="pindiv">
                  <WidgetSpeed :watched_source="pin" />
                </div> -->

                <!-- default -->
                <div v-if="!pin.chart" class="pindiv">
                  <WidgetDefault2 :watched_source="pin" :interval="refresh_ms" />
                </div>
              </b-card>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>
