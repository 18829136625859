<script>
export default {
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {},
  mounted() {},
  destroyed() {},
}
</script>

<template>
  <main class="content" style="padding:0;">
    <iframe
      src="http://localhost:1560/?orgId=30"
      width="100%"
      frameborder="0"
      style="width:calc(100% - 224px); height: 100vh; position:fixed;"
    ></iframe>
  </main>
</template>
