<template>
  <div id="app">
    <MainView />
  </div>
</template>

<script>
import MainView from '@/views/MainView.vue'

export default {
  name: 'Home',
  components: {
    MainView,
  },
}
</script>
