<script>
export default {
  data() {
    return {
      head_icon: 'plug',
      show_device_form: false,
      //show_configuration_form: false,
      device_table: {
        per_page: 5,
        cur_page: 1,
      },
      device_data: {
        device_id: null,
        name: '',
        ingest_interval: 0,
        pull_interval: 0,
        data_value_json: '',
        gateway: {
          gateway_id: null,
        },
        signal_source: {
          signal_source_id: null,
          name: '',
        },
        status: 1,
      },
      device_gateway_id: this.$route.params.gateway_id || '',
      modal_json: {},
      config_json: {},
    }
  },
  methods: {
    toggleDeviceForm() {
      this.clearDeviceForm()
      this.show_device_form = !this.show_device_form
      //this.show_configuration_form = false;
    },
    clearDeviceForm() {
      this.device_data = {
        device_id: null,
        name: '',
        ingest_interval: null,
        pull_interval: null,
        data_value_json: '',
        gateway: {
          gateway_id: null,
        },
        signal_source: {
          signal_source_id: null,
          name: '',
        },
        status: 1,
      }
    },
    submitDevice() {
      this.$store.dispatch('queDevice', this.device_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_device_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getDevice')
        }
      })
      this.clearDeviceForm()
    },
    deleteDevice(_device) {
      this.$bvModal
        .msgBoxConfirm(this.ln['msg_confirm'], {
          ...this.$store.state.modal_opt,
          title: this.ln['confirm_title'],
          okTitle: this.ln['btn_ok'],
          cancelTitle: this.ln['btn_cancel'],
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('delDevice', _device).then((res) => {
              if (res && res.err) {
                this.showMsg(this.ln['msg_error'], 'danger')
              } else {
                this.showMsg(this.ln['msg_success'])
                this.$store.commit('getDevice')
              }
            })
          }
        })
    },
    editDevice(_device) {
      this.clearDeviceForm()
      this.device_data = _device
      if (this.device_data.signal_source == null) {
        this.device_data.signal_source = { signal_source_id: null }
      }

      this.changeDeviceSignalSource(false)

      this.show_device_form = true
      //this.show_configuration_form = false;
    },
    changeDeviceSignalSource(_change) {
      _change = _change == undefined ? true : _change
      this.config_json = {}
      this.modal_json = {}
      let _signal_source = null

      if (this.device_data.signal_source) {
        _signal_source = this.arr_signalsource.find((source) => {
          return source.signal_source_id == this.device_data.signal_source.signal_source_id
        })
      }

      if (_signal_source) {
        _signal_source.signal_source_configs.map((e) => {
          this.config_json[e.slug] = ''

          this.modal_json[e.slug] = ''
          if (e.type) {
            let _json_select = this.$store.state.signalsource.find((source) => {
              return source.signal_source_id == e.type
            })

            this.modal_json[e.slug] = _json_select.signal_source_configs
          }
        })
      }
      if (_change) {
        let json_str = ''
        if (Object.keys(this.config_json).length > 0) {
          json_str = JSON.stringify(this.config_json)
        }
        this.device_data.data_value_json = json_str
      }
    },
    getDeviceJsonString() {
      this.device_data.data_value_json = JSON.stringify(this.config_json)
    },
  },
  filters: {
    pretty: function(value) {
      if (value) {
        return JSON.stringify(JSON.parse(value), null, 2)
      }
    },
  },
  computed: {
    arr_signalsource() {
      let ret = []
      ret = this.$store.state.signalsource.filter((e) => {
        return (
          e.parent == null
          /* (this.show_configuration_form
            ? this.device_data.signal_source.signal_source_id
            : null) */
        )
      })
      return ret || []
    },
    device_gateway() {
      let ret = null
      if (this.device_gateway_id && this.$store.state.gateway) {
        ret = this.$store.state.gateway.find((e) => {
          return e.gateway_id == this.device_gateway_id
        })
      }
      return ret
    },
    arr_device() {
      let ret = []
      if (this.$store.state.device) {
        ret = this.$store.state.device
        if (this.device_gateway_id) {
          ret = this.$store.state.device.filter((e) => {
            return e.gateway.gateway_id == this.device_gateway_id
          })
        }
      }
      return ret
    },

    device_len() {
      return this.arr_device.length || 0
    },
    show_device_pager() {
      return this.device_len > this.device_table.per_page
    },
    device_edit() {
      return this.device_data.device_id ? 1 : 0
    },
  },
  beforeCreate() {
    this.$store.commit('getSignalSource')
    this.$store.commit('getDevice')
    this.$store.commit('getGateway')
  },
}
</script>

<template>
  <main class="content">
    <div
      :class="'pricing-header px-3 pb-3 pb-md-5 mx-auto text-center ' + (arr_device.length > 0 ? 'mini' : '')"
    >
      <fa-icons class="picon mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln['devices_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['devices_desc'] }}</small>
      </p>
      <button class="btn btn-dark" @click="toggleDeviceForm" :disabled="show_device_form">
        <fa-icons icon="plus" />
        {{ ln['btn_addnew'] }}
      </button>
    </div>

    <div>
      <div class="row">
        <div class="col-12 col-xl">
          <section class="widget pb-0">
            <header>
              <h4>
                <b>{{ device_gateway ? device_gateway.name + ' - ' : '' }}</b>
                {{ ln['device_list'] }}
              </h4>
              <div class="widget-controls full">
                <b-form inline>
                  <label class="mr-sm-2 font-weight-bold">{{ ln['gateway'] }} :</label>
                  <b-form-select
                    size="sm"
                    v-model="device_gateway_id"
                    @change="
                      (value) => {
                        $router.push({ path: '/device/' + value })
                      }
                    "
                    :options="$store.state.gateway"
                    value-field="gateway_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option value>{{ ln['form_showall'] }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form>
              </div>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                striped
                :items="arr_device"
                :fields="[
                  {
                    key: 'name',
                    label: ln['form_devicename'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  {
                    key: 'gateway',
                    label: ln['gateway'],
                  },
                  {
                    key: 'sources',
                    label: ln['form_sources'],
                    class: 'text-center',
                  },
                  {
                    key: 'status',
                    label: ln['form_status'],
                    class: 'text-center',
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
                :per-page="device_table.per_page"
                :current-page="device_table.cur_page"
              >
                <template v-slot:cell(gateway)="row">{{ row.item.gateway.name }}</template>

                <template v-slot:cell(sources)="row">
                  <b-button
                    size="xs"
                    variant="warning"
                    class="px-2 font-weight-bold"
                    :to="`/source/${row.item.device_id}`"
                  >
                    {{ row.item.sources.length }}
                    {{ ln['source'] }}
                  </b-button>
                </template>

                <template v-slot:cell(status)="row">
                  <b-button
                    size="xs"
                    :variant="row.item.status ? 'success' : 'danger'"
                    class="px-2 font-weight-normal"
                    @click="$store.dispatch('toggleDeviceStatus', row.item)"
                  >
                    {{ ln['form_' + (row.item.status ? 'active' : 'passive')] }}
                  </b-button>
                </template>

                <template v-slot:cell(action)="row">
                  <b-button size="xs" variant="info" @click="editDevice(row.item)">
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button size="xs" variant="danger" class="ml-2" @click="deleteDevice(row.item)">
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

            <footer class="pb-1">
              <b-pagination
                v-show="show_device_pager"
                v-model="device_table.cur_page"
                :total-rows="device_len"
                :per-page="device_table.per_page"
                align="center"
              ></b-pagination>
              <p class="text-center text-info" v-show="!device_len">
                {{ ln['msg_empty'] }}
              </p>
            </footer>
          </section>
        </div>

        <div
          class="col-12 col-xl-12 order-first order-xl-first"
          v-if="show_device_form /*&& !show_configuration_form*/"
        >
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['device_' + (device_edit ? 'edit' : 'new')] }}
              </h4>
              <div class="widget-controls">
                <a href @click.prevent="toggleDeviceForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitDevice()">
                <b-form-group :label="ln['gateway']">
                  <b-form-select
                    v-model="device_data.gateway.gateway_id"
                    :options="$store.state.gateway"
                    value-field="gateway_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        {{ ln['form_selectone'] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_devicename']">
                  <b-form-input v-model="device_data.name" required placeholder="e.g My PLC"></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_signalsource']">
                  <b-form-select
                    v-model="device_data.signal_source.signal_source_id"
                    :options="arr_signalsource"
                    value-field="signal_source_id"
                    text-field="name"
                    @change="changeDeviceSignalSource()"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" selected>
                        {{ ln['form_customtype'] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="`${ln['form_ingestinterval']} (${ln['form_milisecond']})`">
                  <b-form-input v-model="device_data.ingest_interval"></b-form-input>
                </b-form-group>

                <b-form-group :label="`${ln['form_pullinterval']} (${ln['form_milisecond']})`">
                  <b-form-input v-model="device_data.pull_interval"></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_configjson']">
                  <div class="row">
                    <div class="col-sm-10">
                      <b-form-input
                        v-show="false"
                        v-model="device_data.data_value_json"
                        class="txtjson"
                      ></b-form-input>

                      <b-card class="mt-0 bg-muted">
                        <pre class="m-0 text-muted">{{ device_data.data_value_json | pretty }}</pre>
                      </b-card>
                    </div>

                    <div class="col-sm-2 mt-2 mt-sm-0 px-sm-0">
                      <b-button
                        v-b-modal.modal-2
                        size="sm"
                        variant="info"
                        :disabled="Object.keys(this.config_json).length > 0 ? false : true"
                      >
                        <fa-icons icon="life-ring" class="mr-1" />
                        JSON Helper
                      </b-button>

                      <b-modal id="modal-2" title="BootstrapVue" @ok="getDeviceJsonString()">
                        <b-form>
                          <b-form-group :label="i" v-for="(field, i) in this.modal_json" :key="i">
                            <b-form-input v-model="config_json[i]" v-if="field.length == 0"></b-form-input>

                            <b-form-select
                              v-model="config_json[i]"
                              v-if="field.length > 0"
                              :options="field"
                              value-field="slug"
                              text-field="name"
                              required
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-form>
                      </b-modal>
                    </div>
                  </div>
                </b-form-group>

                <b-button type="submit" variant="dark">
                  {{ ln['btn_' + (device_edit ? 'update' : 'create')] }}
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
