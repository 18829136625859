<script>
export default {
  data() {
    return {
      head_icon: 'desktop',
      show_dashboard_form: false,
      dashboard_table: {
        per_page: 5,
        cur_page: 1,
      },
      dashboard_data: {
        dashboard_id: null,
        name: '',
        slug: '',
      },
    }
  },
  methods: {
    toggleDashboardForm() {
      this.clearDashboardForm()
      this.show_dashboard_form = !this.show_dashboard_form
    },
    clearDashboardForm() {
      this.dashboard_data = {
        dashboard_id: null,
        name: '',
        slug: '',
        status: 1,
      }
    },
    //--
    submitDashboard() {
      this.$store.dispatch('queDashboard', this.dashboard_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_dashboard_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getDashboard')
        }
      })
      this.clearDashboardForm()
    },
    deleteDashboard(_dashboard) {
      this.$bvModal
        .msgBoxConfirm(this.ln['msg_confirm'], {
          ...this.$store.state.modal_opt,
          title: this.ln['confirm_title'],
          okTitle: this.ln['btn_ok'],
          cancelTitle: this.ln['btn_cancel'],
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('delDashboard', _dashboard).then((res) => {
              if (res && res.err) {
                this.showMsg(this.ln['msg_error'], 'danger')
              } else {
                this.showMsg(this.ln['msg_success'])
                this.$store.commit('getDashboard')
              }
            })
          }
        })
    },
    editDashboard(_dashboard) {
      this.clearDashboardForm()
      this.dashboard_data = _dashboard
      this.show_dashboard_form = true
    },
    copyClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showMsg(this.ln['msg_success'])
        },
        () => {
          this.showMsg(this.ln['msg_error'], 'danger')
        }
      )
    },
  },
  computed: {
    arr_dashboard() {
      return this.$store.state.dashboard || []
    },
    dashboard_len() {
      return this.arr_dashboard.length || 0
    },
    show_dashboard_pager() {
      return this.dashboard_len > this.dashboard_table.per_page
    },
    dashboard_edit() {
      return this.dashboard_data.dashboard_id ? 1 : 0
    },
  },
  mounted() {
    this.$store.commit('getDashboard')
  },
}
</script>

<template>
  <main class="content">
    <div
      :class="
        'pricing-header px-3 pb-3 pb-md-5 mx-auto text-center ' + (arr_dashboard.length > 0 ? 'mini' : '')
      "
    >
      <fa-icons class="picon mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln['dashboards_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['dashboards_desc'] }}</small>
      </p>
      <button class="btn btn-dark" @click="toggleDashboardForm" :disabled="show_dashboard_form">
        <fa-icons icon="plus" />
        {{ ln['btn_addnew'] }}
      </button>
    </div>

    <div>
      <div class="row">
        <div class="col-12 col-xl">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['dashboard_list'] }}
              </h4>
              <div class="widget-controls"></div>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                striped
                :items="arr_dashboard"
                :fields="[
                  {
                    key: 'name',
                    label: ln['form_dashboardname'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  {
                    key: 'slug',
                    label: `key`,
                  },
                  {
                    key: 'monitores',
                    label: ln['monitores_title'],
                    class: 'text-center',
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
                :per-page="dashboard_table.per_page"
                :current-page="dashboard_table.cur_page"
              >
                <template v-slot:cell(slug)="row">
                  <b-button class="mr-1" size="xs" variant="info" @click="copyClipboard(row.item.slug)">
                    <fa-icons icon="copy" /> Copy
                  </b-button>
                  {{ row.item.slug.slice(0, 12) }} ••
                </template>

                <template v-slot:cell(monitores)="row">
                  <router-link
                    class="btn btn-xs font-weight-bold bg-warning"
                    :to="`/monitore/${row.item.dashboard_id}`"
                  >
                    {{ row.item.monitores.length }} {{ ln['monitor'] }}
                  </router-link>
                </template>

                <template v-slot:cell(action)="row">
                  <router-link
                    class="btn btn-xs text-white bg-success"
                    :to="`/dashboard/${row.item.dashboard_id}`"
                  >
                    <fa-icons icon="eye" />
                    {{ ln['form_watch'] }}
                  </router-link>

                  <b-button size="xs" variant="info" class="ml-2" @click="editDashboard(row.item)">
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button size="xs" variant="danger" class="ml-2" @click="deleteDashboard(row.item)">
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

            <footer class="pb-1">
              <b-pagination
                v-show="show_dashboard_pager"
                v-model="dashboard_table.cur_page"
                :total-rows="dashboard_len"
                :per-page="dashboard_table.per_page"
                align="center"
              ></b-pagination>
              <p class="text-center text-info" v-show="!dashboard_len">
                {{ ln['msg_empty'] }}
              </p>
            </footer>
          </section>
        </div>

        <div class="col-12 col-xl-4 order-first order-xl-last" v-if="show_dashboard_form">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['dashboard_' + (dashboard_edit ? 'edit' : 'new')] }}
              </h4>
              <div class="widget-controls">
                <a href @click.prevent="toggleDashboardForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitDashboard()">
                <b-form-group :label="ln['form_dashboardname']">
                  <b-form-input
                    v-model="dashboard_data.name"
                    required
                    placeholder="e.g My Dasghboard"
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" variant="dark">
                  {{ ln['btn_' + (dashboard_edit ? 'update' : 'create')] }}
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
