<script>
export default {
  data() {
    return {
      head_icon: 'user',
      user_data: {
        user_id: null,
        name: '',
      },
      email_data: {
        newemail: '',
        curpass: '',
        code: '',
      },
      pass_data: {
        pass: '',
        repass: '',
        curpass: '',
      },
      //user_form_success: 0,
      show_email_form: false,
      show_pass_form: false,
    }
  },
  methods: {
    clearEmailForm() {
      this.email_data = {
        newemail: '',
        curpass: '',
        code: '',
      }
    },
    clearPassForm() {
      this.pass_data = {
        pass: '',
        repass: '',
        curpass: '',
      }
    },
    showEmailForm() {
      this.clearEmailForm()
      this.clearPassForm()
      this.show_email_form = true
      this.show_pass_form = false
    },
    showPassForm() {
      this.clearEmailForm()
      this.clearPassForm()
      this.show_email_form = false
      this.show_pass_form = true
    },
    submitAccount() {
      this.$store.dispatch('queAccount', this.user_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          //this.user_form_success = 3
          this.showMsg(this.ln['msg_success'])
        }
      })
    },
    submitAccountEmail() {
      this.email_data.user_id = this.user_data.user_id
      this.email_data.email = this.user_data.email

      this.$store.dispatch('queAccountEmail', this.email_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          //this.user_form_success = 3
          this.showMsg(this.ln['msg_success'])
          this.show_email_form = false
        }
        this.clearEmailForm()
      })
    },
    submitAccountPass() {
      this.pass_data.user_id = this.user_data.user_id
      this.pass_data.email = this.user_data.email

      this.$store.dispatch('queAccountPass', this.pass_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          //this.user_form_success = 3
          this.showMsg(this.ln['msg_success'])
          this.show_pass_form = false
        }
        this.clearPassForm()
      })
    },
  },
  computed: {
    pass_validate() {
      let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/ //harf ,rakam
      //let reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$/ //küçük harf, büyük harf, rakam

      return this.pass_data.pass == '' || (this.pass_data.pass.length > 5 && reg.test(this.pass_data.pass))
    },
    repass_validate() {
      return this.pass_data.repass == '' || this.pass_data.pass == this.pass_data.repass
    },
  },
  mounted() {
    this.user_data = this.$store.state.user
  },
}
</script>

<template>
  <main class="content">
    <div class="pricing-header px-3 pb-3 pb-md-5 mx-auto text-center">
      <fa-icons class="mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln['account_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['account_desc'] }}</small>
      </p>
    </div>

    <div class="company">
      <div class="row justify-content-md-center">
        <div class="col-12 col-xl-7 order-xl-last">
          <section class="widget pb-0">
            <header>
              <h4>{{ ln['profile_title'] }}</h4>
              <div class="widget-controls"></div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitAccount()">
                <b-form-group :label="ln['form_name']">
                  <b-form-input v-model="user_data.name" required></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_email']">
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center py-2 pl-3 pr-2"
                    >
                      {{ user_data.email }}
                      <b-button @click="showEmailForm()" size="sm" variant="info">{{
                        ln['btn_changemail']
                      }}</b-button>
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <b-form-group :label="ln['form_password']">
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center py-2 pl-3 pr-2"
                    >
                      *********
                      <b-button @click="showPassForm()" size="sm" variant="info">{{
                        ln['btn_changepass']
                      }}</b-button>
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <b-button type="submit" variant="dark">{{ ln['btn_update'] }}</b-button>
              </b-form>
            </div>
          </section>
        </div>

        <div class="col-12 col-xl-5 order-first order-xl-last" v-if="show_email_form">
          <section class="widget pb-0">
            <header>
              <h4>{{ ln['changemail_title'] }}</h4>
              <div class="widget-controls">
                <a href @click.prevent="show_email_form = false" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitAccountEmail()">
                <b-form-group :label="ln['form_curpass']">
                  <b-form-input type="password" v-model="email_data.curpass" required></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_newmail']">
                  <b-form-input type="email" v-model="email_data.newemail" required></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_confcode']">
                  <b-form-input v-model="email_data.code" required></b-form-input>
                </b-form-group>

                <b-button type="submit" variant="dark">{{ ln['btn_send'] }}</b-button>
              </b-form>
            </div>
          </section>
        </div>

        <div class="col-12 col-xl-5 order-first order-xl-last" v-if="show_pass_form">
          <section class="widget pb-0">
            <header>
              <h4>{{ ln['changepass_title'] }}</h4>
              <div class="widget-controls">
                <a href @click.prevent="show_pass_form = false" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitAccountPass()">
                <b-form-group :label="ln['form_curpass']">
                  <b-form-input type="password" v-model="pass_data.curpass" required></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_newpass']">
                  <b-form-input type="password" v-model="pass_data.pass" required></b-form-input>
                  <b-form-invalid-feedback :state="pass_validate">
                    {{ ln['pass_valid'] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="ln['form_repass']">
                  <b-form-input type="password" v-model="pass_data.repass" required></b-form-input>
                  <b-form-invalid-feedback :state="repass_validate">
                    {{ ln['repass_valid'] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button type="submit" :disabled="!pass_validate || !repass_validate" variant="dark">
                  {{ ln['btn_update'] }}
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
