<script>
import FlagsBar from '@/components/FlagsBar'
export default {
  components: {
    FlagsBar,
  },
  data() {
    return {
      show_forget_form: true,
      show_reset_form: false,
      forget_data: {
        email: '',
      },
      reset_data: {
        respass: this.$route.params.respass || '',
        email: '',
        pass: '',
        repass: '',
        curpass: '',
      },
    }
  },
  methods: {
    clearForgetForm() {
      this.forget_data = {
        email: '',
      }
    },
    clearResetForm() {
      this.reset_data = {
        email: '',
        pass: '',
        repass: '',
        curpass: '',
      }
    },
    submitForget() {
      this.$store.dispatch('queForget', this.forget_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_nouser'], 'danger')
        } else {
          this.reset_data.email = this.forget_data.email
          this.reset_data.respass = res
          this.clearForgetForm()
        }
      })
    },
    submitReset() {
      this.$store.dispatch('queResetpass', this.reset_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.$router.push({ path: '/login' }).then(() => {
            this.showMsg(this.ln['msg_success'])
          })
        }
        this.clearResetForm()
      })
    },
  },
  computed: {
    reset_show() {
      return this.reset_data.respass !== '' ? 1 : 0
    },
    pass_validate() {
      let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/ //harf ,rakam
      //let reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$/ //küçük harf, büyük harf, rakam

      return this.reset_data.pass == '' || (this.reset_data.pass.length > 5 && reg.test(this.reset_data.pass))
    },
    repass_validate() {
      return this.reset_data.repass == '' || this.reset_data.pass == this.reset_data.repass
    },
  },
}
</script>

<template>
  <main class="d-flex align-items-center min-vh-100 py-3 py-md-0 login-screen">
    <Particles id="tsparticles" :options="$store.state.particle_opt" />
    <div class="container">
      <div class="card login-card">
        <div class="row no-gutters">
          <div class="col-md-5">
            <img src="../assets/forget_bg.jpg" alt="login" class="login-card-img" />
          </div>
          <div class="col-md-7">
            <div class="card-body">
              <div class="brand-wrapper">
                <img src="../assets/logo.svg" alt="logo" class="logo_login" />
              </div>

              <p class="login-card-description">{{ ln['forget_title'] }}</p>

              <b-form @submit.prevent="submitForget()" v-if="!reset_show">
                <b-form-group>
                  <b-form-input
                    type="email"
                    v-model="forget_data.email"
                    required
                    :placeholder="ln['form_email']"
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" class="btn btn-block mt-4 login-btn mb-5">
                  {{ ln['btn_send'] }}
                </b-button>
              </b-form>

              <b-form @submit.prevent="submitReset()" v-if="reset_show">
                <b-form-group>
                  <b-form-input
                    type="password"
                    v-model="reset_data.curpass"
                    required
                    :placeholder="ln['form_curpass']"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    type="password"
                    v-model="reset_data.pass"
                    required
                    :placeholder="ln['form_newpass']"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="pass_validate">
                    {{ ln['pass_valid'] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    type="password"
                    v-model="reset_data.repass"
                    required
                    :placeholder="ln['form_repass']"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="repass_validate">
                    {{ ln['repass_valid'] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                  type="submit"
                  :disabled="!pass_validate || !repass_validate"
                  class="btn btn-block mt-4 login-btn mb-5"
                >
                  {{ ln['btn_reset'] }}
                </b-button>
              </b-form>

              <p class="login-card-footer-text">
                {{ ln['dont_account'] }}
                <router-link to="/signup" class="text-info">{{ ln['register_here'] }}</router-link>
              </p>

              <FlagsBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
