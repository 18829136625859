import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import App from "./App.vue";

import store from "./store.js";
import router from "./router.js";

Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas);
Vue.component("fa-icons", FontAwesomeIcon);

import Particles from "particles.vue";
Vue.use(Particles);

import "./assets/css/main.css";
import "./assets/css/resp.css";

Vue.mixin({
  computed: {
    ln: function() {
      return this.$store.state.lang_sett[this.$store.state.lang];
    }
  },
  methods: {
    showMsg(_msg, _variant = "success") {
      this.$bvToast.toast(_msg, {
        toaster: "b-toaster-bottom-full",
        toastClass: "frmToast",
        variant: _variant
      });
    },
    frmDate(time) {
      var currentDate = new Date(time);

      /* var date = currentDate.getDate();
      var month = currentDate.getMonth(); //Be careful! January is 0 not 1
      var year = currentDate.getFullYear(); */

      //return date + "/" + (month + 1) + "/" + year;
      return (
        currentDate.toLocaleDateString("tr") +
        " " +
        currentDate.toLocaleTimeString("tr")
      );
    }
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
