<script>
export default {
  data() {
    return {
      head_icon: "satellite-dish",
      show_gateway_form: false,
      gateway_table: {
        per_page: 5,
        cur_page: 1,
      },
      gateway_data: {
        gateway_id: null,
        name: "",
        location: {
          location_id: null,
        },
        status: 1,
      },
      gateway_location_id: this.$route.params.location_id || "",
    };
  },
  methods: {
    toggleGatewayForm() {
      this.clearGatewayForm();
      this.show_gateway_form = !this.show_gateway_form;
    },
    clearGatewayForm() {
      this.gateway_data = {
        gateway_id: null,
        name: "",
        location: {
          location_id: null,
        },
        status: 1,
      };
    },
    submitGateway() {
      this.$store.dispatch("queGateway", this.gateway_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln["msg_error"], "danger");
        } else {
          this.show_gateway_form = false;
          this.showMsg(this.ln["msg_success"]);
          this.$store.commit("getGateway");
        }
      });
      this.clearGatewayForm();
    },
    deleteGateway(_gateway) {
      this.$bvModal
        .msgBoxConfirm(this.ln["msg_confirm"], {
          ...this.$store.state.modal_opt,
          title: this.ln["confirm_title"],
          okTitle: this.ln["btn_ok"],
          cancelTitle: this.ln["btn_cancel"],
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("delGateway", _gateway).then((res) => {
              if (res && res.err) {
                this.showMsg(this.ln["msg_error"], "danger");
              } else {
                this.showMsg(this.ln["msg_success"]);
                this.$store.commit("getGateway");
              }
            });
          }
        });
    },
    editGateway(_gateway) {
      this.clearGatewayForm();
      this.gateway_data = _gateway;
      this.show_gateway_form = true;
    },
  },
  computed: {
    gateway_location() {
      let ret = null;
      if (this.gateway_location_id && this.$store.state.location) {
        ret = this.$store.state.location.find((e) => {
          return e.location_id == this.gateway_location_id;
        });
      }
      return ret;
    },
    arr_gateway() {
      let ret = [];
      if (this.$store.state.gateway) {
        ret = this.$store.state.gateway;
        if (this.gateway_location_id) {
          ret = this.$store.state.gateway.filter((e) => {
            return e.location.location_id == this.gateway_location_id;
          });
        }
      }
      return ret;
    },

    gateway_len() {
      return this.arr_gateway.length || 0;
    },
    show_gateway_pager() {
      return this.gateway_len > this.gateway_table.per_page;
    },
    gateway_edit() {
      return this.gateway_data.gateway_id ? 1 : 0;
    },
  },
  beforeCreate() {
    this.$store.commit("getGateway");
    this.$store.commit("getLocation");
  },
};
</script>

<template>
  <main class="content">
    <div
      :class="
        'pricing-header px-3 pb-3 pb-md-5 mx-auto text-center ' +
        (arr_gateway.length > 0 ? 'mini' : '')
      "
    >
      <fa-icons class="picon mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln["gateways_title"] }}</h1>
      <p class="lead">
        <small>{{ ln["gateways_desc"] }}</small>
      </p>
      <button
        class="btn btn-dark"
        @click="toggleGatewayForm"
        :disabled="show_gateway_form"
      >
        <fa-icons icon="plus" />
        {{ ln["btn_addnew"] }}
      </button>
    </div>

    <div>
      <div class="row">
        <div class="col-12 col-xl">
          <section class="widget pb-0">
            <header>
              <h4>
                <b>{{
                  gateway_location ? gateway_location.name + " - " : ""
                }}</b>
                {{ ln["gateway_list"] }}
              </h4>
              <div class="widget-controls full">
                <b-form inline>
                  <label class="mr-sm-2 font-weight-bold"
                    >{{ ln["location"] }} :</label
                  >
                  <b-form-select
                    size="sm"
                    v-model="gateway_location_id"
                    @change="
                      (value) => {
                        $router.push({ path: '/gateway/' + value });
                      }
                    "
                    :options="$store.state.location"
                    value-field="location_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option value>{{
                        ln["form_showall"]
                      }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form>
              </div>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                striped
                :items="arr_gateway"
                :fields="[
                  {
                    key: 'name',
                    label: ln['form_gatewayname'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  {
                    key: 'location',
                    label: ln['location'],
                  },
                  {
                    key: 'devices',
                    label: ln['devices_title'],
                    class: 'text-center',
                  },
                  {
                    key: 'status',
                    label: ln['form_status'],
                    class: 'text-center',
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
                :per-page="gateway_table.per_page"
                :current-page="gateway_table.cur_page"
              >
                <template v-slot:cell(location)="row">{{
                  row.item.location.name
                }}</template>

                <template v-slot:cell(devices)="row">
                  <router-link
                    class="btn btn-xs font-weight-bold bg-warning"
                    :to="`/device/${row.item.gateway_id}`"
                  >
                    {{ row.item.devices.length }}
                    {{ ln["device"] }}</router-link
                  >
                </template>

                <template v-slot:cell(status)="row">
                  <b-button
                    size="xs"
                    :variant="row.item.status ? 'success' : 'danger'"
                    class="px-2 font-weight-normal"
                    @click="$store.dispatch('toggleGatewayStatus', row.item)"
                  >
                    {{ ln["form_" + (row.item.status ? "active" : "passive")] }}
                  </b-button>
                </template>

                <template v-slot:cell(action)="row">
                  <b-button
                    size="xs"
                    variant="info"
                    @click="editGateway(row.item)"
                  >
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button
                    size="xs"
                    variant="danger"
                    class="ml-2"
                    @click="deleteGateway(row.item)"
                  >
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

            <footer class="pb-1">
              <b-pagination
                v-show="show_gateway_pager"
                v-model="gateway_table.cur_page"
                :total-rows="gateway_len"
                :per-page="gateway_table.per_page"
                align="center"
              ></b-pagination>
              <p class="text-center text-info" v-show="!gateway_len">
                {{ ln["msg_empty"] }}
              </p>
            </footer>
          </section>
        </div>

        <div
          class="col-12 col-xl-4 order-first order-xl-last"
          v-if="show_gateway_form"
        >
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln["gateway_" + (gateway_edit ? "edit" : "new")] }}
              </h4>
              <div class="widget-controls">
                <a href @click.prevent="toggleGatewayForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitGateway()">
                <b-form-group :label="ln['location']">
                  <b-form-select
                    v-model="gateway_data.location.location_id"
                    :options="$store.state.location"
                    value-field="location_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        {{ ln["form_selectone"] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_gatewayname']">
                  <b-form-input
                    v-model="gateway_data.name"
                    required
                    placeholder="e.g TrexDCAS"
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" variant="dark">
                  {{ ln["btn_" + (gateway_edit ? "update" : "create")] }}
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
