<script>
export default {
  data() {
    return {
      active_company: 0,
    }
  },
  computed: {
    arr_company() {
      return this.$store.state.company || []
    },
    arr_location() {
      return this.$store.state.location || []
    },
    company_location() {
      return (
        this.arr_location.filter((_location) => {
          return (
            this.arr_company[this.active_company] &&
            _location.company.company_id == this.arr_company[this.active_company].company_id
          )
        }) || []
      )
    },
    arr_country() {
      return this.$store.state.country || []
    },
  },
  mounted() {
    this.$store.commit('getCountry')
    this.$store.commit('getCompany')
    this.$store.commit('getLocation')
  },
}
</script>

<template>
  <div>
    <div class="pricing-header px-3 pb-3 pt-5 pb-md-4 mx-auto text-center">
      <h1 class="display-4">{{ ln['locations_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['locations_desc'] }}</small>
      </p>
    </div>

    <div class="location">
      <section class="widget mb-0 h-100">
        <div class="row">
          <div class="col-12 col-xl-4">
            <header class="pb-2">
              <h5><fa-icons class="mr-2" icon="briefcase" /> {{ ln['companies_title'] }}</h5>
              <div class="widget-controls">
                <a data-widgster="close" title="Close" href="#"><i class="la la-remove"></i></a>
              </div>
            </header>
            <div class="widget-body">
              <div class="list-group list-group-flush" v-if="Boolean(arr_company.length)">
                <b-link
                  :href="_company.name"
                  v-for="(_company, i) in arr_company"
                  :key="`comp_${i}`"
                  :class="
                    `d-flex justify-content-between align-items-center list-group-item list-group-item-action ${
                      active_company == i ? 'active' : ''
                    }`
                  "
                  @click.prevent="active_company = i"
                >
                  {{ _company.name }}

                  <router-link
                    :to="`/location/${_company.company_id}`"
                    class="btn btn-xs btn-dark ml-0 ml-md-auto mt-2 mt-md-0 font-weight-bold"
                  >
                    {{ ln['locations_title'] }}
                  </router-link>
                </b-link>
              </div>
              <span class="text-danger" v-if="!Boolean(arr_company.length)">
                {{ ln['msg_empty'] }}
              </span>
            </div>
          </div>
          <div class="col-12 col-xl-8 border-left">
            <header class="pb-2">
              <h5>{{ ln['locations_title'] }}</h5>
              <div class="widget-controls">
                <a data-widgster="close" title="Close" href="#"><i class="la la-remove"></i></a>
              </div>
            </header>
            <div class="widget-body">
              <ul class="list-group" v-if="Boolean(company_location.length)">
                <li
                  v-for="(_location, i) in company_location"
                  :key="`dloc_${i}`"
                  class="list-group-item d-flex flex-column flex-md-row align-items-center"
                >
                  <fa-icons class="mr-2" icon="map-marker-alt" />
                  {{ `${_location.name}, ${_location.city.country.name}` }}
                  <span class="badge badge-pill badge-warning ml-0 ml-md-4">
                    {{ _location.gateways.length }} {{ ln['gateway'] }}
                  </span>

                  <router-link
                    :to="`/gateway/${_location.location_id}`"
                    class="btn btn-sm btn-info ml-0 ml-md-auto mt-2 mt-md-0"
                  >
                    {{ ln['gateways_title'] }}
                  </router-link>
                </li>
              </ul>
              <span class="text-danger" v-if="!Boolean(company_location.length)">
                {{ ln['msg_empty'] }}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
