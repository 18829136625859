var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-3"},[_c('LineChart',{staticStyle:{"width":"100%","height":"360px"},attrs:{"chart-data":this.chart_data || {},"options":{
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: true },
        elements: {
          point: { radius: 0 },
        },
        tooltips: { enabled: true },
        scales: {
          yAxes: [
            {
              display: true,
            } ],
          xAxes: [
            {
              display: true,
              type: 'time',
              time: {
                displayFormats: {
                  second: 'mm:ss',
                },
              },
              distribution: 'series',
              ticks: {
                display: true,
              },
            } ],
        },
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }