<script>
import BarChart from '@/widgets/BarChart'

export default {
  props: ['watched_source', 'interval'],
  components: {
    BarChart,
  },
  data() {
    return {
      watch_timer: null,
      watch_data: ['{"time":"","value":0,"max":0,"min":0}'],
      watched_config: '',
      chart_data: null,
    }
  },
  methods: {
    fillChart() {
      let _datasets = []
      let _colors = ['#f44336', '#17a2b8', '#4caf50', '#673ab7', '#fbb031', '#795548', '#607d8b']
      this.watch_data.forEach((sensor, i) => {
        if (sensor.values && sensor.values.length > 0) {
          let _data = sensor.values
            .map((data) => {
              return { x: JSON.parse(data).time, y: JSON.parse(data).value }
            })
            .reverse()

          _datasets.push({
            label: sensor.key,
            barPercentage: 1.4,
            backgroundColor: _colors[i],
            data: _data,
          })
        }
      })

      /* let _data = this.watch_data.map((e) => {
        return JSON.parse(e).value
      })
      let _label = this.watch_data.map((e) => {
        return JSON.parse(e).time
      }) */
      //console.log(_datasets)
      //console.log(_labels)

      this.chart_data = {
        datasets: _datasets,
      }
    },
    watchHandler(_monitore) {
      /*
      this.$store.dispatch('monitorValue', _monitore).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res)
          this.watch_data = res.data[0].values
          this.fillChart()
        }
      })
      */
      let req = JSON.parse(_monitore.data_value_json)
      this.$store.dispatch('getKeyValue', req).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res.data)
          this.watch_data = res.data
          this.fillChart()
        }
      })
    },
    beginWatch(_monitore) {
      this.endWatch()
      /*
      let _interval = _monitore.source.device.gateway.pull_interval
      if (_monitore.source.pull_interval) {
        _interval = _monitore.source.pull_interval
      } else if (_monitore.source.device.pull_interval) {
        _interval = _monitore.source.device.pull_interval
      }
      */
      let _interval = this.interval

      console.log(`watching every ${_interval} ms`)

      this.watchHandler(_monitore)
      if (_interval > 0) {
        this.watch_timer = setInterval(() => {
          this.watchHandler(_monitore)
        }, _interval)
      }
    },
    endWatch() {
      console.log(`End watching..`)
      clearInterval(this.watch_timer)
      this.watch_timer = null
    },
  },
  watch: {
    //eslint-disable-next-line
    watched_source: function(newVal, oldVal) {
      this.watch_data = newVal
      this.fillChart()
    },
    interval: function() {
      this.beginWatch(this.watched_source)
    },
  },
  mounted() {
    this.beginWatch(this.watched_source)
    //this.watch_data = this.watched_source
    //this.fillChart()
  },
  destroyed() {
    this.endWatch()
  },
}
</script>

<template>
  <div>
    <div class="mt-3">
      <BarChart
        style="width: 100%; height: 360px"
        :chart-data="this.chart_data || {}"
        :options="{
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: true },
          elements: {
            point: { radius: 0 },
          },
          tooltips: { enabled: true },
          scales: {
            yAxes: [
              {
                display: true,
              },
            ],
            xAxes: [
              {
                display: true,
                type: 'time',
                time: {
                  displayFormats: {
                    second: 'mm:ss',
                  },
                },
                distribution: 'series',
                ticks: {
                  display: true,
                },
              },
            ],
          },
        }"
      />
    </div>
  </div>
</template>
