<script>
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  props: ['watched_source'],
  components: {
    VueSvgGauge,
  },
  data() {
    return {
      watch_timer: null,
      watch_data: {
        data: [
          {
            key: '',
            value: '',
            time: '',
          },
        ],
      },
      watched_config: '',
      chart_data: null,
      perValue: 0,
    }
  },
  methods: {
    fillChart() {
      if (this.watch_data.length > 0) {
        this.chart_data = JSON.parse(this.watch_data[0])
        let min = this.watched_config.min //this.chart_data.min
        let max = this.watched_config.max //this.chart_data.max
        this.perValue = (((this.chart_data.value - min) * 10) / (max - min)).toFixed(2)
      }
    },
    watchHandler(_monitore) {
      this.$store.dispatch('monitorValue', _monitore).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          this.watch_data = res.data[0].values
          this.fillChart()
        }
      })
    },
    beginWatch(_monitore) {
      this.endWatch()
      let _interval = _monitore.source.device.gateway.pull_interval
      if (_monitore.source.pull_interval) {
        _interval = _monitore.source.pull_interval
      } else if (_monitore.source.device.pull_interval) {
        _interval = _monitore.source.device.pull_interval
      }

      console.log(`${_monitore.source.name} is watching every ${_interval} ms`)

      if (_interval > 0) {
        this.watchHandler(_monitore)
        this.watch_timer = setInterval(() => {
          this.watchHandler(_monitore)
        }, _interval)
      }
    },
    endWatch() {
      console.log(`End watching..`)
      clearInterval(this.watch_timer)
      this.watch_timer = null
    },
  },
  mounted() {
    if (this.watched_source.data_value_json) {
      this.watched_config = JSON.parse(this.watched_source.data_value_json)
    }
    this.beginWatch(this.watched_source)
  },
  computed: {},
  destroyed() {
    this.endWatch()
  },
}
</script>

<template>
  <div class="chspeed">
    <VueSvgGauge
      :start-angle="-70"
      :end-angle="70"
      :value="Number(this.perValue)"
      :min="0"
      :max="10"
      :gauge-color="[
        { offset: 0, color: '#347AB0' },
        { offset: 100, color: '#8CDFAD' },
      ]"
    />
    <span>{{ chart_data ? chart_data.value.toFixed(2) : '-' }}</span>
    <h6 class="mt-3">
      <small>
        <fa-icons icon="clock" />
        {{ chart_data ? frmDate(chart_data.time) : '-' }}
      </small>
    </h6>
  </div>
</template>
<style>
.chspeed {
  position: relative;
  top: 10px;
}
.chspeed span {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 20px;
}
</style>
