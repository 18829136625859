<script>
import FlagsBar from '@/components/FlagsBar'
export default {
  components: {
    FlagsBar,
  },
  data() {
    return {
      login_data: {
        email: '',
        pass: '',
      },
    }
  },
  methods: {
    clearLoginForm() {
      this.login_data = {
        email: '',
        pass: '',
      }
    },
    submitLogin() {
      this.$store.dispatch('queLogin', this.login_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_nouser'], 'danger')
        } else {
          this.$router.push({ path: '/' })
        }
      })

      this.clearLoginForm()
    },
  },
}
</script>

<template>
  <main class="d-flex align-items-center min-vh-100 py-3 py-md-0 login-screen">
    <Particles id="tsparticles" :options="$store.state.particle_opt" />
    <div class="container">
      <div class="card login-card">
        <div class="row no-gutters">
          <div class="col-md-5">
            <img src="../assets/login_bg.jpg" alt="login" class="login-card-img" />
          </div>
          <div class="col-md-7">
            <div class="card-body">
              <div class="brand-wrapper">
                <img src="../assets/logo.svg" alt="logo" class="logo_login" />
              </div>

              <p class="login-card-description">{{ ln['login_title'] }}</p>

              <b-form @submit.prevent="submitLogin()">
                <b-form-group>
                  <b-form-input
                    type="email"
                    v-model="login_data.email"
                    required
                    :placeholder="ln['form_email']"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    type="password"
                    v-model="login_data.pass"
                    required
                    :placeholder="ln['form_password']"
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" class="btn btn-block login-btn mb-5">
                  {{ ln['btn_login'] }}
                </b-button>
              </b-form>

              <router-link to="/forget" class="forgot-password-link"> {{ ln['forgot_pass'] }}</router-link>
              <p class="login-card-footer-text">
                {{ ln['dont_account'] }}
                <router-link to="/signup" class="text-info">
                  {{ ln['register_here'] }}
                </router-link>
              </p>

              <FlagsBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
