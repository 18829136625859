<script>
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
export default {
  data() {
    return {
      head_icon: 'chalkboard-teacher',
      show_analyst_form: false,
      show_company_form: false,
      analyst_table: {
        per_page: 5,
        cur_page: 1,
      },
      analyst_data: {
        analyst_id: null,
        name: '',
        email: '',
        pass: '',
        data_value_json: '',
        repass: '',
        analyst_companies: [],
      },
    }
  },
  methods: {
    showPass(hpass) {
      let bytes = AES.decrypt(hpass, 'IOTP')
      return bytes.toString(Utf8)
    },
    showCompanyForm(_analyst) {
      this.clearAnalystForm()
      this.clearCompanyForm()
      this.analyst_data = _analyst
      this.show_analyst_form = false
      this.show_company_form = true
    },
    toggleAnalystForm() {
      this.clearAnalystForm()
      this.clearCompanyForm()
      this.show_analyst_form = !this.show_analyst_form
      this.show_company_form = false
    },
    clearAnalystForm() {
      this.analyst_data = {
        analyst_id: null,
        name: '',
        email: '',
        pass: '',
        data_value_json: '',
        repass: '',
        analyst_companies: [],
      }
    },
    clearCompanyForm() {
      this.company_data = {
        company_id: null,
        name: '',
      }
    },
    setupAnalyst(_analyst_id) {
      let _auser = { ...this.analyst_data }
      _auser.analyst_id = _analyst_id
      console.log(_auser)
      this.$store.dispatch('addAnalystToDb', _auser).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_analyst_form = false
          this.show_company_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getAnalyst')
        }
      })
      this.clearAnalystForm()
    },
    submitAnalyst() {
      this.$store.dispatch('queAnalyst', this.analyst_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
          this.clearAnalystForm()
        } else {
          this.show_analyst_form = false
          this.show_company_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getAnalyst')
          console.log(res)
          this.setupAnalyst(res.analyst_id)
          this.clearAnalystForm()
        }
      })
    },
    removeAnalyst() {
      this.$store.dispatch('delAnalystToDb', this.analyst_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_analyst_form = false
          this.show_company_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getAnalyst')
        }
      })
      this.clearAnalystForm()
    },
    deleteAnalyst(_analyst) {
      this.$bvModal
        .msgBoxConfirm(this.ln['msg_confirm'], {
          ...this.$store.state.modal_opt,
          title: this.ln['confirm_title'],
          okTitle: this.ln['btn_ok'],
          cancelTitle: this.ln['btn_cancel'],
        })
        .then((value) => {
          //console.log(value)
          if (value) {
            this.$store.dispatch('delAnalyst', _analyst).then((res) => {
              if (res && res.err) {
                console.log(res)
                this.showMsg(this.ln['msg_error'], 'danger')
              } else {
                this.showMsg(this.ln['msg_success'])
                this.$store.commit('getAnalyst')

                this.removeAnalyst()
              }
            })
          }
        })
    },
    editAnalyst(_analyst) {
      this.clearAnalystForm()
      this.analyst_data = _analyst
      this.show_analyst_form = true
      this.show_company_form = false
    },
    setupCompany(_company_id) {
      let _acompany = { ...this.analyst_data }
      _acompany.companyId = _company_id
      this.$store.dispatch('addCompanyToDb', _acompany).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_analyst_form = false
          this.show_company_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getAnalyst')
        }
      })
      this.clearAnalystForm()
    },
    addCompany(_company) {
      this.$store.dispatch('queAnalystCompany', _company).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
          this.clearCompanyForm()
        } else {
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getAnalyst')

          this.setupCompany(_company.company_id)
          this.clearCompanyForm()
        }
      })
    },
    removeCompany(_company_id) {
      let _acompany = { ...this.analyst_data }
      _acompany.companyId = _company_id
      this.$store.dispatch('delCompanyToDb', _acompany).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_analyst_form = false
          this.show_company_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getAnalyst')
        }
      })
      this.clearAnalystForm()
    },
    delCompany(_company) {
      this.$bvModal
        .msgBoxConfirm(this.ln['msg_confirm'], {
          ...this.$store.state.modal_opt,
          title: this.ln['confirm_title'],
          okTitle: this.ln['btn_ok'],
          cancelTitle: this.ln['btn_cancel'],
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('delAnalystCompany', _company).then((res) => {
              if (res && res.err) {
                this.showMsg(this.ln['msg_error'], 'danger')
              } else {
                this.showMsg(this.ln['msg_success'])
                this.$store.commit('getAnalyst')

                this.removeCompany(_company.company_id)
              }
            })
          }
        })
    },
  },
  computed: {
    arr_analyst() {
      return this.$store.state.analyst
    },
    analyst_len() {
      return this.arr_analyst.length || 0
    },
    show_analyst_pager() {
      return this.analyst_len > this.analyst_table.per_page
    },
    analyst_edit() {
      return this.analyst_data.analyst_id ? true : false
    },
    //--
    arr_company() {
      return this.$store.state.company
    },
    //--

    analyst_company() {
      return this.arr_company.map((_company) => {
        let _analyst_company_id = null

        let _analyst = { ...this.analyst_data }
        _analyst?.analyst_companies?.find((_acomp) => {
          if (_acomp.company.company_id == _company.company_id) {
            _analyst_company_id = _acomp.analyst_company_id
          }
        })

        return {
          company_id: _company.company_id,
          name: _company.name,
          analyst_company_id: _analyst_company_id,
          analyst_id: this.analyst_data.analyst_id,
        }
      })
    },

    pass_validate() {
      let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/ //harf ,rakam
      //let reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$/ //küçük harf, büyük harf, rakam

      return (
        this.analyst_data.pass == '' ||
        (this.analyst_data.pass.length > 5 && reg.test(this.analyst_data.pass))
      )
    },
    repass_validate() {
      return this.analyst_data.repass == '' || this.analyst_data.pass == this.analyst_data.repass
    },
  },
  mounted() {
    this.$store.commit('getCompany')
    this.$store.commit('getAnalyst')
  },
}
</script>

<template>
  <main class="content">
    <div
      :class="
        'pricing-header px-3 pb-3 pb-md-5 mx-auto text-center ' + (arr_analyst.length > 0 ? 'mini' : '')
      "
    >
      <fa-icons class="picon mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln['analysts_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['analysts_desc'] }}</small>
      </p>
      <button class="btn btn-dark" @click="toggleAnalystForm" :disabled="show_analyst_form">
        <fa-icons icon="plus" />
        {{ ln['btn_addnew'] }}
      </button>
    </div>

    <div>
      <div class="row">
        <div class="col-12 col-xl">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['analyst_list'] }}
              </h4>
              <div class="widget-controls"></div>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                striped
                :items="arr_analyst"
                :fields="[
                  {
                    key: 'name',
                    label: ln['form_name'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  {
                    key: 'email',
                    label: ln['form_email'],
                    class: 'text-center',
                  },
                  {
                    key: 'analyst_companies',
                    label: ln['analyst_companies'],
                    class: 'text-center',
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
                :per-page="analyst_table.per_page"
                :current-page="analyst_table.cur_page"
              >
                <template v-slot:cell(analyst_companies)="row">
                  <b-button
                    size="xs"
                    variant="warning"
                    class="px-2 font-weight-bold"
                    @click="showCompanyForm(row.item)"
                  >
                    {{ row.item.analyst_companies.length }} {{ ln['company'] }}
                  </b-button>
                </template>

                <template v-slot:cell(action)="row">
                  <b-button size="xs" variant="info" @click="editAnalyst(row.item)">
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button size="xs" variant="danger" class="ml-2" @click="deleteAnalyst(row.item)">
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

            <footer class="pb-1">
              <b-pagination
                v-show="show_analyst_pager"
                v-model="analyst_table.cur_page"
                :total-rows="analyst_len"
                :per-page="analyst_table.per_page"
                align="center"
              ></b-pagination>
              <p class="text-center text-info" v-show="!analyst_len">
                {{ ln['msg_empty'] }}
              </p>
            </footer>
          </section>
        </div>

        <div class="col-12 col-xl-4 order-first order-xl-last" v-if="show_analyst_form && !show_company_form">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['analyst_' + (analyst_edit ? 'edit' : 'new')] }}
              </h4>
              <div class="widget-controls">
                <a href @click.prevent="toggleAnalystForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitAnalyst()">
                <b-form-group :label="ln['form_name']">
                  <b-form-input
                    v-model="analyst_data.name"
                    required
                    placeholder="e.g Test User"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_email']">
                  <b-form-input
                    :disabled="analyst_edit"
                    type="email"
                    v-model="analyst_data.email"
                    required
                    placeholder="e.g user@user.com"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_password']" v-if="analyst_edit">
                  <b-form-input :disabled="true" :value="showPass(analyst_data.pass)"></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_password']" v-if="!analyst_edit">
                  <b-form-input type="password" v-model="analyst_data.pass" required></b-form-input>
                  <b-form-invalid-feedback :state="pass_validate">
                    {{ ln['pass_valid'] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group :label="ln['form_repass']" v-if="!analyst_edit">
                  <b-form-input type="password" v-model="analyst_data.repass" required></b-form-input>
                  <b-form-invalid-feedback :state="repass_validate">
                    {{ ln['repass_valid'] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                  type="submit"
                  :disabled="!analyst_edit && (!pass_validate || !repass_validate)"
                  variant="dark"
                >
                  {{ ln['btn_' + (analyst_edit ? 'update' : 'create')] }}
                </b-button>
              </b-form>
            </div>
          </section>
        </div>

        <div class="col-12 col-xl-4 order-first order-xl-last" v-if="show_company_form">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['analyst_companies'] }}
              </h4>
              <h5>
                <small class="text-danger">{{ analyst_data.name }}</small>
              </h5>
              <div class="widget-controls">
                <a href @click.prevent="show_company_form = false" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-table
                responsive
                striped
                :items="analyst_company"
                :fields="[
                  {
                    key: 'name',
                    label: ln['form_companyname'],
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
              >
                <template v-slot:cell(action)="row">
                  <b-button
                    v-if="!row.item.analyst_company_id || row.item.analyst_id !== analyst_data.analyst_id"
                    size="xs"
                    variant="success"
                    class="ml-2"
                    @click="addCompany(row.item)"
                  >
                    <fa-icons icon="plus" /> {{ ln['btn_add'] }}
                  </b-button>
                  <b-button
                    v-if="row.item.analyst_company_id && row.item.analyst_id == analyst_data.analyst_id"
                    size="xs"
                    variant="danger"
                    class="ml-2"
                    @click="delCompany(row.item)"
                  >
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
