<script>
import DonutChart from '@/widgets/DonutChart'

export default {
  props: ['watched_source', 'interval'],
  components: {
    DonutChart,
  },
  data() {
    return {
      watch_timer: null,
      watch_data: {
        data: [
          {
            key: '',
            value: '',
            time: '',
          },
        ],
      },
      watched_config: '',
      chart_data: null,
    }
  },
  methods: {
    fillChart() {
      //let _datasets = []
      let _labels = []
      let _values = []
      let _colors = ['#f44336', '#17a2b8', '#4caf50', '#673ab7', '#fbb031', '#795548', '#607d8b']
      this.watch_data.forEach((sensor) => {
        if (sensor.values && sensor.values.length > 0) {
          _values.push(JSON.parse(sensor.values[0]).value)
        }
        _labels.push(sensor.key)
      })

      this.chart_data = {
        labels: _labels,
        datasets: [
          {
            barPercentage: 0.7,
            backgroundColor: _colors,
            data: _values,
          },
        ],
      }
    },
    watchHandler(_monitore) {
      /*
      this.$store.dispatch('monitorValue', _monitore).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res);
          this.watch_data = res.data[0].values
          this.fillChart()
        }
      })
      */
      let req = JSON.parse(_monitore.data_value_json)
      this.$store.dispatch('getKeyValue', req).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res.data)
          this.watch_data = res.data
          this.fillChart()
        }
      })
    },
    beginWatch(_monitore) {
      this.endWatch()
      /*
      let _interval = _monitore.source.device.gateway.pull_interval
      if (_monitore.source.pull_interval) {
        _interval = _monitore.source.pull_interval
      } else if (_monitore.source.device.pull_interval) {
        _interval = _monitore.source.device.pull_interval
      }
      */
      let _interval = this.interval

      console.log(`watching every ${_interval} ms`)

      this.watchHandler(_monitore)
      if (_interval > 0) {
        this.watch_timer = setInterval(() => {
          this.watchHandler(_monitore)
        }, _interval)
      }
    },
    endWatch() {
      console.log(`End watching..`)
      clearInterval(this.watch_timer)
      this.watch_timer = null
    },
  },
  watch: {
    //eslint-disable-next-line
    watched_source: function(newVal, oldVal) {
      this.watch_data = newVal
      this.fillChart()
    },
    interval: function() {
      this.beginWatch(this.watched_source)
    },
  },
  mounted() {
    this.beginWatch(this.watched_source)
    //this.watch_data = this.watched_source
    //this.fillChart()
  },
  destroyed() {
    this.endWatch()
  },
}
</script>

<template>
  <div>
    <DonutChart
      :chart-data="this.chart_data"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        //legend: { position: 'right' },
      }"
    />
  </div>
</template>
