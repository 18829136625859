<script>
import FlagsBar from '@/components/FlagsBar'
export default {
  components: {
    FlagsBar,
  },
  data() {
    return {
      signup_data: {
        name: '',
        email: '',
        pass: '',
        repass: '',
      },
    }
  },
  methods: {
    clearSignupForm() {
      this.signup_data = {
        name: '',
        email: '',
        pass: '',
        repass: '',
      }
    },
    submitSignup() {
      this.$store.dispatch('queSignup', this.signup_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.$router.push({ path: '/' }).then(() => {
            this.showMsg(this.ln['msg_success'])
          })
          /* this.$router.push({ path: '/login' }).then(() => {
            this.showMsg(this.ln['msg_success'])
          }) */
        }
      })

      this.clearSignupForm()
    },
  },
  computed: {
    pass_validate() {
      let reg = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/ //harf ,rakam
      //let reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]+)$/ //küçük harf, büyük harf, rakam

      return (
        this.signup_data.pass == '' || (this.signup_data.pass.length > 5 && reg.test(this.signup_data.pass))
      )
    },
    repass_validate() {
      return this.signup_data.repass == '' || this.signup_data.pass == this.signup_data.repass
    },
  },
}
</script>

<template>
  <main class="d-flex align-items-center min-vh-100 py-3 py-md-0 login-screen">
    <Particles id="tsparticles" :options="$store.state.particle_opt" />
    <div class="container">
      <div class="card login-card">
        <div class="row no-gutters">
          <div class="col-md-5">
            <img src="../assets/signup_bg.jpg" alt="login" class="login-card-img" />
          </div>
          <div class="col-md-7">
            <div class="card-body">
              <div class="brand-wrapper">
                <img src="../assets/logo.svg" alt="logo" class="logo_login" />
              </div>

              <p class="login-card-description">{{ ln['signup_title'] }}</p>

              <b-form @submit.prevent="submitSignup()">
                <b-form-group>
                  <b-form-input
                    v-model="signup_data.name"
                    required
                    :placeholder="ln['form_name']"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    type="email"
                    v-model="signup_data.email"
                    required
                    :placeholder="ln['form_email']"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    type="password"
                    v-model="signup_data.pass"
                    required
                    :placeholder="ln['form_password']"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="pass_validate">
                    {{ ln['pass_valid'] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <b-form-input
                    type="password"
                    v-model="signup_data.repass"
                    required
                    :placeholder="ln['form_repass']"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="repass_validate">
                    {{ ln['repass_valid'] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                  type="submit"
                  :disabled="!pass_validate || !repass_validate"
                  class="btn btn-block login-btn mb-5"
                >
                  {{ ln['btn_register'] }}
                </b-button>
              </b-form>

              <router-link to="/forget" class="forgot-password-link">{{ ln['forgot_pass'] }}</router-link>
              <p class="login-card-footer-text">
                {{ ln['sign_account'] }}
                <router-link to="/login" class="text-info">{{ ln['sign_in'] }}</router-link>
              </p>

              <FlagsBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
