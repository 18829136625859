import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";
/*const ax = axios.create({
  baseURL: process.env.VUE_APP_CONFIG_API_URL
});
//Vue.prototype.$ax = ax; */

import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import AES from "crypto-js/aes";

Vue.use(Vuex);

let ax = axios.create({
  // http://localhost:1510/api/v1/
  baseURL: process.env.VUE_APP_CONFIG_API_URL
});

let dax = axios.create({
  // http://localhost:1520/api/v1/
  baseURL: process.env.VUE_APP_DATA_API_URL
});

export default new Vuex.Store({
  state: {
    user: null,
    //user: { user_id: 1, name: "Hi Boss..", email: "boss@iotpark.com" },
    country: [],
    role: [],
    analyst: [],
    dashboard: [],
    company: [],
    location: [],
    gateway: [],
    group: [],
    device: [],
    source: [],
    monitore: [],
    branch: [],
    watch_timer: null,
    signalsource: [],
    chart: [],
    lastId: null,
    modal_opt: {
      title: "Please Confirm",
      headerBgVariant: "dark",
      headerTextVariant: "white",
      headerCloseVariant: "white",
      //buttonSize: 'sm',
      okVariant: "success",
      cancelVariant: "danger",
      footerClass: "p-2 flex-row-reverse justify-content-start",
      hideHeaderClose: false,
      centered: true
    },
    particle_opt: {
      fpsLimit: 60,
      interactivity: {
        detectsOn: "canvas",
        events: {
          onHover: {
            enable: true,
            mode: "repulse"
          }
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 2,
            opacity: 0.8,
            size: 40,
            speed: 3
          },
          push: {
            quantity: 4
          },
          repulse: {
            distance: 150,
            duration: 0.4,
            speed: 0.1
          }
        }
      },
      particles: {
        color: {
          value: "#333467"
        },
        links: {
          color: "#333467",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1
        },
        collisions: {
          enable: true
        },
        move: {
          direction: "none",
          enable: true,
          outMode: "bounce",
          random: false,
          speed: 3,
          straight: false
        },
        number: {
          density: {
            enable: true,
            value_area: 600
          },
          value: 100
        },
        opacity: {
          value: 0.5
        },
        shape: {
          type: "circle"
        },
        size: {
          random: true,
          value: 5
        }
      },
      detectRetina: true
    },
    lang: "en",
    lang_sett: {
      en: {
        login_title: "Sign into your account",
        form_email: "Email address",
        form_password: "Password",
        btn_login: "Login",
        forgot_pass: "Forgot password?",
        dont_account: "Don't have an account?",
        register_here: "Register here",
        signup_title: "Create your account",
        form_name: "Name",
        form_repass: "Re-type password",
        btn_register: "Register",
        sign_account: "Sign into your account",
        sign_in: "Sign in",
        pass_valid:
          "Your password must be 5-12 characters long, contain letters and numbers.",
        repass_valid: "Password does not match.",
        forget_title: "Forgot password",
        btn_send: "Send",
        form_newpass: "New password",
        form_curpass: "Current password",
        btn_reset: "Reset",
        account_title: "Account",
        account_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        profile_title: "Profile Information",
        btn_update: "Update",
        btn_create: "Create",
        btn_changemail: "Change email",
        btn_changepass: "Change password",
        msg_confirm: "Please confirm that you want to delete this content ?",
        msg_updated: "Update completed successfully",
        msg_success: "Operation completed successfully",
        msg_error:
          "Operation could not be done. Please check your inputs and try again!",
        msg_empty: "There are no data",
        changemail_title: "Change Email",
        changepass_title: "Change Password",
        form_newmail: "New email",
        form_confcode: "Confirmation code",
        btn_logout: "Logout",
        settings_title: "Settings",
        mongateways_title: "Monitored Gateways",
        mongateways_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        companies_title: "Companies",
        companies_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        locations_title: "Locations",
        locations_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        gateways_title: "Gateways",
        gateways_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        groups_title: "Groups",
        groups_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        analysts_title: "Analysts",
        analysts_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        devices_title: "Devices",
        devices_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        sources_title: "Sources",
        sources_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        monitores_title: "Monitore",
        monitores_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        dashboards_title: "Dashboards",
        dashboards_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        btn_details: "Details",
        home: "Home",
        dashboard: "Dashboard",
        company: "Company",
        location: "Location",
        monitor: "Monitor",
        gateway: "Gateway",
        group: "Group",
        device: "Device",
        btn_addnew: "Add New",
        company_list: "Company List",
        company_new: "New Company",
        company_edit: "Edit Company",
        location_list: "Location List",
        location_new: "New Location",
        location_edit: "Edit Location",
        gateway_list: "Gateway List",
        gateway_new: "New Gateway",
        gateway_edit: "Edit Gateway",
        group_list: "Group List",
        group_new: "New Group",
        group_edit: "Edit Group",
        group_users: "Group Users",
        analyst_list: "Analyst List",
        analyst_new: "New Analyst",
        analyst_edit: "Edit Analyst",
        analyst_companies: "Analyst Companies",
        device_list: "Device List",
        device_new: "New Device",
        device_edit: "Edit Device",
        source_list: "Source List",
        source_new: "New Source",
        source_edit: "Edit Source",
        source_watch: "Watch Source",
        monitore_list: "Monitore List",
        monitore_new: "New Monitore",
        monitore_edit: "Edit Monitore",
        form_companyname: "Company name",
        form_country: "Country",
        form_selectone: "Please select",
        form_selectnone: "None",
        form_customtype: "Custom type",
        form_showall: "Show all",
        form_induscode: "Industry code",
        form_active: "Active",
        form_passive: "Passive",
        form_pin: "Pinned",
        form_unpin: "Unpinned",
        form_watch: "Watch",
        form_design: "Design",
        form_title: "Title",
        form_status: "Status",
        form_action: "Action",
        form_locationname: "Location name",
        form_city: "City",
        form_state: "State",
        form_address: "Address",
        form_gatewayname: "Gateway name",
        form_devicename: "Device name",
        form_sourcename: "Source name",
        form_sourcekey: "Source key",
        form_configurations: "Configurations",
        form_signalsource: "Signal source",
        form_ingestinterval: "Ingest interval",
        form_pullinterval: "Pull interval",
        form_configjson: "Config JSON",
        form_milisecond: "Milisecond",
        form_groupname: "Group name",
        form_groupusers: "Group users",
        form_permissions: "Permissions",
        form_charttype: "Chart Type",
        form_chartsize: "Chart Size",
        user: "User",
        configuration: "Configuration",
        source: "Source",
        form_usermail: "User email",
        btn_add: "Add",
        btn_del: "Remove",
        form_username: "User name",
        confirm_title: "Please confirm",
        btn_ok: "OK",
        btn_cancel: "Cancel",
        msg_nouser: "There is no user with the information you entered !",
        btn_showpass: "Show Password"
      },
      tr: {
        login_title: "Hesabınızla giriş yapın",
        form_email: "Eposta adresiniz",
        form_password: "Şifreniz",
        btn_login: "Giriş",
        forgot_pass: "Parolanızı mı unuttunuz?",
        dont_account: "Hesabınız yok mu?",
        register_here: "Kayıt olun",
        signup_title: "Hesabınızı oluşturun",
        form_name: "Adınız",
        form_repass: "Şifre tekrarı",
        btn_register: "Kayıt olun",
        sign_account: "Hesabınızla giriş yapın",
        sign_in: "Giriş yap",
        pass_valid:
          "Şifreniz 5-12 karakter uzunluğunda olmalı, harf ve rakamlardan oluşmalıdır.",
        repass_valid: "Şifreniz eşleşmiyor.",
        forget_title: "Şifre hatırlatma",
        btn_send: "Gönder",
        form_newpass: "Yeni şifreniz",
        form_curpass: "Mevcut şifreniz",
        btn_reset: "Sıfırla",
        account_title: "Hesabım",
        account_desc:
          "Yinelenen bir sayfa içeriğinin okuyucunun dikkatini dağıttığı bilinen bir gerçektir. Lorem Ipsum kullanmanın amacı, sürekli 'buraya metin gelecek",
        profile_title: "Profil Bilgilerim",
        btn_update: "Güncelle",
        btn_create: "Ekle",
        btn_changemail: "Eposta değiştir",
        btn_changepass: "Şifre değiştir",
        msg_confirm: "İçeriği silmek istediğinizden emin misiniz ?",
        msg_updated: "Güncelleme başarıyla tamamlandı",
        msg_success: "İşlem başarıyla tamamlandı",
        msg_error:
          "İşleminiz yapılamadı. Lütfen bilgilerinizi kontrol ederek tekrar deneyiniz !",
        msg_empty: "Veri bulunmuyor",
        changemail_title: "Eposta Değiştir",
        changepass_title: "Şifre Değiştir",
        form_newmail: "Yeni eposta",
        form_confcode: "Onay kodu",
        btn_logout: "Çıkış yap",
        settings_title: "Ayarlar",
        mongateways_title: "İzlenen Ağ Geçitleri",
        mongateways_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        companies_title: "Şirketler",
        companies_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        locations_title: "Lokasyonlar",
        locations_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        gateways_title: "Ağ Geçitleri",
        gateways_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        groups_title: "Gruplar",
        groups_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        analysts_title: "Analistler",
        analysts_desc:
          "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
        devices_title: "Cihazlar",
        devices_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        sources_title: "Kaynaklar",
        sources_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        monitores_title: "İzlemeler",
        monitores_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        dashboards_title: "Göstergeler",
        dashboards_desc:
          "Şu anda birçok masaüstü yayıncılık paketi ve web sayfa düzenleyicisi, varsayılan mıgır metinler olarak Lorem Ipsum kullanmaktadır.",
        btn_details: "Detaylar",
        home: "Ana sayfa",
        dashboard: "Panel",
        company: "Şirket",
        location: "Lokasyon",
        monitor: "İzleme",
        gateway: "Ağ geçidi",
        group: "Grup",
        device: "Cihaz",
        btn_addnew: "Yeni Ekle",
        company_list: "Şirket Listesi",
        company_new: "Yeni Şirket",
        company_edit: "Şirketi Düzenle",
        location_list: "Lokasyon Listesi",
        location_new: "Yeni Lokasyon",
        location_edit: "Lokasyonu Düzenle",
        gateway_list: "Ağ Geçidi Listesi",
        gateway_new: "Yeni Ağ Geçidi",
        gateway_edit: "Ağ Geçidini Düzenle",
        group_list: "Grup Listesi",
        group_new: "Yeni Grup",
        group_edit: "Grubu Düzenle",
        group_users: "Grup Üyeleri",
        analyst_list: "Analist List",
        analyst_new: "Yeni Analist",
        analyst_edit: "Analist Düzenle",
        analyst_companies: "Analist Şirketleri",
        device_list: "Cihaz Listesi",
        device_new: "Yeni Cihaz",
        device_edit: "Cihazı Düzenle",
        source_list: "Kaynak Listesi",
        source_new: "Yeni Kaynak",
        source_edit: "Kaynağı Düzenle",
        source_watch: "Kaynağı İzle",
        monitore_list: "İzleme Listesi",
        monitore_new: "Yeni İzleme",
        monitore_edit: "İzlemeyi Düzenle",
        form_companyname: "Şirket ismi",
        form_country: "Ülke",
        form_selectone: "Lütfen seçiniz",
        form_selectnone: "Yok",
        form_customtype: "Özel tip",
        form_showall: "Hepsini göster",
        form_induscode: "Endüstri kodu",
        form_active: "Aktif",
        form_passive: "Pasif",
        form_pin: "Sabit",
        form_unpin: "Sabit değil",
        form_watch: "İzle",
        form_design: "Tasarla",
        form_titlw: "Başlık",
        form_status: "Durum",
        form_action: "İşlem",
        form_locationname: "Lokasyon ismi",
        form_city: "Şehir",
        form_state: "Bölge",
        form_address: "Adres",
        form_gatewayname: "Ağ geçidi ismi",
        form_devicename: "Cihaz ismi",
        form_sourcename: "Kaynak ismi",
        form_sourcekey: "Kaynak anahtarı",
        form_configurations: "Konfigurasyonlar",
        form_signalsource: "Sinyal kaynağı",
        form_ingestinterval: "Besleme aralığı",
        form_pullinterval: "Çekme aralığı",
        form_configjson: "Konfigurasyon JSON",
        form_milisecond: "Milisaniye",
        form_groupname: "Grup ismi",
        form_groupusers: "Grup üyeleri",
        form_permissions: "İzinler",
        form_charttype: "Grafik Tipi",
        form_chartsize: "Grafik Boyutu",
        user: "Üye",
        configuration: "Konfigurasyon",
        source: "Kaynak",
        form_usermail: "Üye eposta",
        btn_add: "Ekle",
        btn_del: "Sil",
        form_username: "Üye ismi",
        confirm_title: "Lütfen onaylayın",
        btn_ok: "Evet",
        btn_cancel: "İptal",
        msg_nouser:
          "Girmiş olduğunuz bilgilere sahip bir kullanıcı kaydı bulunamadı !",
        btn_showpass: "Şifreyi Göster"
      }
    }
  },
  mutations: {
    //login
    login(state) {
      state.user = {
        user_id: 1,
        user_token: "hiboss.."
      };
    },

    //logout
    logout(state) {
      delete ax.defaults.headers["Authorization"];
      delete dax.defaults.headers["Authorization"];
      state.user = null;
    },

    //db get analyst list
    async getAnalyst(state) {
      await ax
        .post("q", {
          query: `{analyst(where: {owner_id: {_eq: ${state.user.user_id}}}, order_by: {analyst_id: asc}) {
            analyst_id name email pass data_value_json analyst_companies{ analyst_company_id company{
              company_id
              name
            }}}}`
        })
        .then(res => {
          state.analyst = res.data.data.analyst;
        });
    },

    //db get dashboard list
    async getDashboard(state) {
      await ax
        .post("q", {
          query: `{
            dashboard(where: {owner_id: {_eq: ${state.user.user_id}}}, order_by: {dashboard_id: desc}) {
              dashboard_id slug name monitores{monitore_id name pin size data_value_json query chart{chart_id name}}}}`
          /*
          query: `{dashboard(where: {owner_id: {_eq: ${state.user.user_id}}}, order_by: {dashboard_id: desc}) {
            dashboard_id slug name monitores(order_by: {monitore_id: asc}, where: {source: {device: {gateway: {location: {company: {owner_id: {_eq: 1}}}}}}}) { monitore_id pin size}}}`
            */
        })
        .then(res => {
          state.dashboard = res.data.data.dashboard;
        });
    },

    //db get monitore list
    async getMonitore(state) {
      await ax
        .post("q", {
          query: `{monitore(where: {dashboard: {owner_id: {_eq: ${state.user.user_id}}}}, order_by: {monitore_id: desc}) { 
            monitore_id name pin size data_value_json query dashboard{dashboard_id name} chart{chart_id name}}}`
          /*
          query: `{monitore(order_by: {monitore_id: asc}, where: {source: { device: { gateway: { location: { company: { owner_id: { _eq: ${state.user.user_id} }}}}}}}) {
            monitore_id pin size data_value_json dashboard{dashboard_id name} chart{chart_id name} source{ source_id name pull_interval ingest_interval slug data_value_json device{ device_id name pull_interval ingest_interval data_value_json signal_source_id gateway{ gateway_id name pull_interval ingest_interval }}}}}`
          */
        })
        .then(res => {
          state.monitore = res.data.data.monitore;
        });
    },

    //db get company list
    async getCompany(state) {
      await ax
        .post("q", {
          query: `{company(where: {owner_id: {_eq: ${state.user.user_id}}}, order_by: {company_id: asc}) {
            company_id country {country_id name} icode name user {user_id name} status locations {location_id}}}`
        })
        .then(res => {
          state.company = res.data.data.company;
        });
    },

    //db get location list
    async getLocation(state) {
      await ax
        .post("q", {
          query: `{location(order_by: {location_id: asc}, where: {company: {owner_id: {_eq: ${state.user.user_id}}}}) {
            location_id name state address status city {city_id name country{country_id name}} company {company_id name} gateways {gateway_id}}}`
        })
        .then(res => {
          state.location = res.data.data.location;
        });
    },

    //db get gateway list
    async getGateway(state) {
      await ax
        .post("q", {
          query: `{gateway(order_by: {gateway_id: asc}, where: {location: {company: {owner_id: {_eq: ${state.user.user_id}}}}}) {gateway_id name status location {location_id name} devices {device_id}}}`
        })
        .then(res => {
          state.gateway = res.data.data.gateway;
        });
    },

    //db get device list
    async getDevice(state) {
      await ax
        .post("q", {
          query: `{device(order_by: {device_id: asc}, where: { gateway: { location: { company: { owner_id: { _eq: ${state.user.user_id} }}}}}) {
              device_id name pull_interval ingest_interval data_value_json status signal_source{ signal_source_id name} sources{ source_id name signal_source_id ingest_interval pull_interval data_value_json} gateway{ gateway_id name}}}`
        })
        .then(res => {
          state.device = res.data.data.device;
        });
    },

    //db get source list
    async getSource(state) {
      await ax
        .post("q", {
          query: `{source(order_by: {source_id: asc}, where: { device: { gateway: { location: { company: { owner_id: { _eq: ${state.user.user_id} }}}}}}) {
            source_id name ingest_interval pull_interval data_value_json slug status signal_source{ signal_source_id name} device {device_id name signal_source {signal_source_id name }}}}`
        })
        .then(res => {
          state.source = res.data.data.source;
        });
    },

    //db get signal source
    async getSignalSource(state) {
      await ax
        .post("q", {
          query: `{signal_source { signal_source_id parent name signal_source_configs { signal_source_config_id name slug type }}}`
        })
        .then(res => {
          state.signalsource = res.data.data.signal_source;
        });
    },

    //db get chart
    async getChart(state) {
      await ax
        .post("q", {
          query: `{chart { chart_id name status chart_configs { chart_config_id name slug }}}`
        })
        .then(res => {
          state.chart = res.data.data.chart;
        });
    },

    //db get group list
    async getGroup(state) {
      await ax
        .post("q", {
          query: `{group(order_by: {group_id: asc} where: {owner_id: {_eq: ${state.user.user_id}}}) {group_id name group_users {user {user_id name email}} group_roles {role {role_id name}} }}`
        })
        .then(res => {
          state.group = res.data.data.group;
        });
    },

    //db get country list
    async getCountry(state) {
      await ax
        .post("q", {
          query: `{country {country_id name cities {city_id name}}}`
        })
        .then(res => {
          state.country = res.data.data.country;
        });
    },

    //db get country list
    async getRole(state) {
      await ax
        .post("q", {
          query: `{role {role_id name}}`
        })
        .then(res => {
          //console.log(response.data.data)
          state.role = res.data.data.role;
        });
    },

    //set last_insert id
    setLastId(state, lastId) {
      state.lastId = lastId;
    },

    //db get keys xxx
    async getBranch(state) {
      var branch = [];
      //var slug = [];
      await ax
        .post("q", {
          query: `{ device(order_by: {device_id: asc}, where: {
            gateway: {
              location: {
                company: { owner_id: {_eq: ${state.user.user_id}}}
              }
            }
          }) { device_id name gateway {gateway_id name location {location_id name company {company_id name}}}}}`
          /*
          `{
            device(order_by: {device_id: asc}, where: {
              gateway: { gateway_id: {_in: [4]}, 
                location: { location_id: {_in: [1]}, 
                  company: { company_id: {_in: [1,2]}, owner_id: {_eq: ${state.user.user_id}}}
                }
              }
            }) {
              device_id
              gateway {
                gateway_id
                name
                location {
                  location_id
                  name
                  company {
                    company_id
                    name
                  }
                }
              }
            }
          }`
          */
        })
        .then(res => {
          //state.device = res.data.data.device;
          if (res.data.data.device.length > 0) {
            state.branch = res.data.data.device;
            /*
            res.data.data.device.forEach(device => {
              branch.push("device-" + device.device_id);
            });
            */
          }
          console.log(branch);
        });
      /*
      await dax
        .post("getdata", {
          slug: slug,
          limit: 10
        })
        .then(res => {
          //console.log(res);
          state.keys=[];
        });
        */
    }
  },
  actions: {
    //db del analyst
    //eslint-disable-next-line
    async addAnalystToGrafana({ commit, dispatch }, params) {
      await ax.post("grafana/user/add", params).then(res => {
        console.log(res);

        let analyst_data = {
          analyst_id: params.analyst_id,
          name: params.name,
          data_value_json: JSON.stringify(res.data)
        };
        dispatch("queAnalyst", analyst_data);
        /*
          if (res.data.data.delete_analyst_by_pk) {
            commit("getAnalyst");
          } else {
            return { err: 1 };
          }
          */
      });
    },

    //db del analyst
    //eslint-disable-next-line
    async addAnalystToDb({ commit, dispatch }, params) {
      await dax.post("role/add", params).then(() => {
        dispatch("addAnalystToGrafana", params);
        /*
          if (res.data.data.delete_analyst_by_pk) {
            commit("getAnalyst");
          } else {
            return { err: 1 };
          }
          */
      });
    },

    //db add analyst
    //eslint-disable-next-line
    async queAnalyst({ state, commit, dispatch }, params) {
      console.log(params);
      var hpass = AES.encrypt(params.pass, "IOTP").toString();
      //var bytes  = CryptoJS.AES.decrypt(hpass, 'secret key 123');
      //var pass = bytes.toString(CryptoJS.enc.Utf8);
      let str_json = JSON.stringify(params.data_value_json);
      let _q = `mutation {insert_analyst_one(object: {
          name: "${params.name}", email: "${params.email}", pass: "${hpass}", owner_id: ${state.user.user_id} }){analyst_id}}`;

      if (params.analyst_id) {
        _q = `mutation {update_analyst_by_pk(pk_columns: {
          analyst_id: ${params.analyst_id}}, _set: {name: "${params.name}", data_value_json: ${str_json} }){analyst_id}}`;
      }

      return await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_analyst_one ||
          res.data.data.update_analyst_by_pk
        ) {
          commit("getAnalyst");
          if (res.data.data.insert_analyst_one) {
            return res.data.data.insert_analyst_one;
          }
        } else {
          return { err: 1 };
        }
      });
    },

    //db del analyst
    //eslint-disable-next-line
    async delAnalystToGrafana({ commit, dispatch }, params) {
      let _auser = JSON.parse(params.data_value_json);
      await ax.post("grafana/user/del", _auser).then(res => {
        console.log(res);
      });
    },

    //db del analyst
    //eslint-disable-next-line
    async delAnalystToDb({ commit, dispatch }, params) {
      await dax.post("role/del", params).then(() => {
        dispatch("delAnalystToGrafana", params);
        /*
          if (res.data.data.delete_analyst_by_pk) {
            commit("getAnalyst");
          } else {
            return { err: 1 };
          }
          */
      });
    },

    //db del analyst
    async delAnalyst({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_analyst_by_pk(analyst_id: ${params.analyst_id}) {analyst_id}}`
        })
        .then(res => {
          if (res?.data?.data?.delete_analyst_by_pk) {
            commit("getAnalyst");
          } else {
            return { err: 1 };
          }
        });
    },

    //--

    //eslint-disable-next-line
    async addCompanyToDb({ commit, dispatch }, params) {
      await dax.post("role/view/add", params).then(() => {
        //commit("getAnalyst");
      });
    },

    //db add analyst company
    async queAnalystCompany({ commit }, params) {
      let _q = `mutation {insert_analyst_company_one(object: {analyst_id: ${params.analyst_id}, company_id: ${params.company_id}}) {
          analyst_company_id
        }}`;

      await ax.post("q", { query: _q }).then(res => {
        if (res.data.data.insert_analist_company_one) {
          commit("getAnalyst");
        } else {
          return { err: 1 };
        }
      });
    },

    //eslint-disable-next-line
    async delCompanyToDb({ commit, dispatch }, params) {
      await dax.post("role/view/del", params).then(() => {
        //commit("getAnalyst");
      });
    },

    //db del analyst company
    async delAnalystCompany({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_analyst_company(where: {analyst_company_id: {_eq: ${params.analyst_company_id}}}) {
                returning {analyst_company_id} }}`
        })
        .then(() => {
          commit("getAnalyst");
        });
    },

    //db add dashboard
    async queDashboard({ state, commit }, params) {
      let _q = `mutation {insert_dashboard_one(object: {
          name: "${params.name}", owner_id: ${state.user.user_id} }){dashboard_id}}`;

      if (params.dashboard_id) {
        _q = `mutation {update_dashboard_by_pk(pk_columns: {
            dashboard_id: ${params.dashboard_id}}, _set: {name: "${params.name}" }){dashboard_id}}`;
      }

      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_dashboard_one ||
          res.data.data.update_dashboard_by_pk
        ) {
          commit("getDashboard");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del dashboard,
    async delDashboard({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_dashboard_by_pk(dashboard_id: ${params.dashboard_id}) {dashboard_id}}`
        })
        .then(res => {
          if (res.data.data.delete_dashboard_by_pk) {
            commit("getDashboard");
          } else {
            return { err: 1 };
          }
        });
    },

    //db set dashboard status
    async toggleDashboardStatus({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation { update_dashboard_by_pk(pk_columns: {
              dashboard_id: ${params.dashboard_id}}, _set: {
                  status: ${Number(!params.status)}}) {dashboard_id} }`
        })
        .then(() => {
          commit("getDashboard");
        });
    },

    //--

    //db add company
    async queCompany({ state, commit }, params) {
      let _q = `mutation {insert_company_one(object: {
          name: "${params.name}", owner_id: ${state.user.user_id}, status: ${params.status}, icode: "${params.icode}", country_id: ${params.country.country_id}
        }){country_id}}`;

      if (params.company_id) {
        _q = `mutation {update_company_by_pk(pk_columns: {
            company_id: ${params.company_id}}, _set: {name: "${params.name}", country_id: ${params.country.country_id}, icode: "${params.icode}"
        }){country_id}}`;
      }

      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_company_one ||
          res.data.data.update_company_by_pk
        ) {
          commit("getCompany");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del company,
    async delCompany({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_company_by_pk(company_id: ${params.company_id}) {company_id}}`
        })
        .then(res => {
          if (res.data.data.delete_company_by_pk) {
            commit("getCompany");
          } else {
            return { err: 1 };
          }
        });
    },

    //db set company status
    async toggleCompanyStatus({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation { update_company_by_pk(pk_columns: {
              company_id: ${params.company_id}}, _set: {
                  status: ${Number(!params.status)}}) {company_id} }`
        })
        .then(() => {
          commit("getCompany");
        });
    },

    //--

    //db add location
    async queLocation({ commit }, params) {
      let _q = `mutation {insert_location_one(object: {
            name: "${params.name}", company_id: ${params.company.company_id}, city_id: ${params.city.city_id}, address: "${params.address}", state: "${params.state}", status: ${params.status}}) 
        {location_id}}`;

      if (params.location_id) {
        _q = `mutation {update_location_by_pk(pk_columns: {
              location_id: ${params.location_id}}, _set: {name: "${params.name}", company_id: ${params.company.company_id}, city_id: ${params.city.city_id}, address: "${params.address}", state: "${params.state}"
          }){location_id}}`;
      }

      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_location_one ||
          res.data.data.update_company_by_pk
        ) {
          commit("getLocation");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del location,
    async delLocation({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_location_by_pk(location_id: ${params.location_id}) {location_id}}`
        })
        .then(res => {
          if (res.data.data.delete_location_by_pk) {
            commit("getLocation");
          } else {
            return { err: 1 };
          }
        });
    },

    //db set location status
    async toggleLocationStatus({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation { update_location_by_pk(pk_columns: {
                location_id: ${params.location_id}}, _set: {
                    status: ${Number(!params.status)}}) {location_id} }`
        })
        .then(() => {
          commit("getLocation");
        });
    },

    //--

    //db add gateway
    async queGateway({ commit }, params) {
      let _q = `mutation {insert_gateway_one(object: {
              name: "${params.name}", location_id: ${params.location.location_id}, status: ${params.status}}) 
          {gateway_id}}`;

      if (params.gateway_id) {
        _q = `mutation {update_gateway_by_pk(pk_columns: {
                gateway_id: ${params.gateway_id}}, _set: {name: "${params.name}", location_id: ${params.location.location_id}
            }){gateway_id}}`;
      }

      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_gateway_one ||
          res.data.data.update_gateway_by_pk
        ) {
          commit("getGateway");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del gateway,
    async delGateway({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_gateway_by_pk(gateway_id: ${params.gateway_id}) {gateway_id}}`
        })
        .then(res => {
          if (res.data.data.delete_gateway_by_pk) {
            commit("getGateway");
          } else {
            return { err: 1 };
          }
        });
    },

    //db set gateway status
    async toggleGatewayStatus({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation { update_gateway_by_pk(pk_columns: {
                gateway_id: ${params.gateway_id}}, _set: {
                    status: ${Number(!params.status)}}) {gateway_id} }`
        })
        .then(() => {
          commit("getGateway");
        });
    },

    //--

    //db add device
    async queDevice({ commit }, params) {
      let str_json = JSON.stringify(params.data_value_json);
      let _q = `mutation {insert_device_one(object: {
              name: "${params.name}", ingest_interval: ${params.ingest_interval}, pull_interval: ${params.pull_interval}, 
              signal_source_id: ${params.signal_source.signal_source_id}, data_value_json: ${str_json}, 
              gateway_id: ${params.gateway.gateway_id}, status: ${params.status}}) 
          {device_id}}`;

      if (params.device_id) {
        _q = `mutation {update_device_by_pk(pk_columns: {
                device_id: ${params.device_id}}, _set: {name: "${params.name}", ingest_interval: ${params.ingest_interval}, 
                pull_interval: ${params.pull_interval}, signal_source_id: ${params.signal_source.signal_source_id}, 
                data_value_json: ${str_json}, gateway_id: ${params.gateway.gateway_id}
            }){device_id}}`;
      }

      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_device_one ||
          res.data.data.update_device_by_pk
        ) {
          commit("getDevice");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del device,
    async delDevice({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_device_by_pk(device_id: ${params.device_id}) {device_id}}`
        })
        .then(res => {
          if (res.data.data.delete_device_by_pk) {
            commit("getDevice");
          } else {
            return { err: 1 };
          }
        });
    },

    //db set device status
    async toggleDeviceStatus({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation { update_device_by_pk(pk_columns: {
                device_id: ${params.device_id}}, _set: {
                    status: ${Number(!params.status)}}) {device_id} }`
        })
        .then(() => {
          commit("getDevice");
        });
    },

    //--

    //db add source
    async queSource({ commit }, params) {
      let str_json = JSON.stringify(params.data_value_json);
      let slug = params.slug !== "" ? `slug: "${params.slug}",` : "";
      let _q = `mutation {insert_source_one(object: {
              name: "${params.name}", ${slug} ingest_interval: ${params.ingest_interval}, pull_interval: ${params.pull_interval}, 
              signal_source_id: ${params.signal_source.signal_source_id}, data_value_json: ${str_json}, 
              device_id: ${params.device.device_id}, status: ${params.status}}) 
          {source_id}}`;

      if (params.source_id) {
        _q = `mutation {update_source_by_pk(pk_columns: {
                source_id: ${params.source_id}}, _set: {name: "${params.name}", ${slug} ingest_interval: ${params.ingest_interval}, 
                pull_interval: ${params.pull_interval}, signal_source_id: ${params.signal_source.signal_source_id}, 
                data_value_json: ${str_json}, device_id: ${params.device.device_id}
            }){source_id}}`;
      }
      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_source_one ||
          res.data.data.update_source_by_pk
        ) {
          commit("getSource");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del source,
    async delSource({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_source_by_pk(source_id: ${params.source_id}) {source_id}}`
        })
        .then(res => {
          if (res.data.data.delete_source_by_pk) {
            commit("getSource");
          } else {
            return { err: 1 };
          }
        });
    },

    //db set source status
    async toggleSourceStatus({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation { update_source_by_pk(pk_columns: {
                source_id: ${params.source_id}}, _set: {
                    status: ${Number(!params.status)}}) {source_id} }`
        })
        .then(() => {
          commit("getSource");
        });
    },

    //--

    //db add monitore
    async queMonitore({ commit }, params) {
      let str_json = JSON.stringify(params.data_value_json);
      let query_json = JSON.stringify(params.query);
      let _q = `mutation { insert_monitore_one(object: {
        dashboard_id: ${params.dashboard.dashboard_id}, chart_id: ${params.chart.chart_id}, size: ${params.size}, data_value_json: ${str_json}, query: ${query_json}, name: "${params.name}"}) {monitore_id }}`;

      if (params.monitore_id) {
        _q = `mutation { update_monitore_by_pk(pk_columns: { monitore_id: ${params.monitore_id}}, _set: {
            dashboard_id: ${params.dashboard.dashboard_id}, chart_id: ${params.chart.chart_id}, size: ${params.size}, data_value_json: ${str_json}, query: ${query_json}, name: "${params.name}"}) {monitore_id }}`;
      }
      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_monitore_one ||
          res.data.data.update_monitore_by_pk
        ) {
          commit("getMonitore");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del monitore,
    async delMonitore({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_monitore_by_pk(monitore_id: ${params.monitore_id}) {monitore_id}}`
        })
        .then(res => {
          if (res.data.data.delete_monitore_by_pk) {
            commit("getMonitore");
          } else {
            return { err: 1 };
          }
        });
    },

    //db set monitore pin
    async toggleMonitorePin({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation { update_monitore_by_pk(pk_columns: {
                monitore_id: ${params.monitore_id}}, _set: {
                    pin: ${Number(!params.pin)}}) {monitore_id} }`
        })
        .then(() => {
          commit("getMonitore");
        });
    },

    //--

    //db get monitor value
    //eslint-disable-next-line
    async monitorValue({ commit }, params) {
      /*
      let limit = 5;
      if (params.data_value_json) {
        let arr_json = JSON.parse(params.data_value_json);
        limit = arr_json.limit || limit;
      }
      */

      //let _q = "INSERT INTO conditions(time, location, temperature, humidity) VALUES (NOW(), 'office', 70.0, 50.0);";
      //let _q = `SELECT data_ts, key,value, time, label FROM data WHERE key="${params.source.slug}" ORDER BY data_ts DESC LIMIT ${limit};`;

      //--
      // find slug
      let slug = `device-${params.source.device.device_id}:${params.source.slug}`;
      if (params.source.device.signal_source_id == 13) {
        //datlog
        let device_json = JSON.parse(params.source.device.data_value_json);
        let source_json = JSON.parse(params.source.data_value_json);
        slug = `datlog-${device_json.serino}:${source_json.key}`;
      }
      //--

      return await dax
        //.post("pull", {
        .post("getdata", {
          slug: slug,
          limit: 10
        })
        .then(res => {
          //console.log(res);
          return res;
        });
    },

    //eslint-disable-next-line
    async getKeyValue({ commit }, req) {
      //--

      return await dax
        //.post("pull", {
        .post("getdata", req)
        .then(res => {
          //console.log(res);
          return res;
        });
    },

    /*
    //db add device config
    async queDeviceConfiguration({ commit }, params) {
      let str_json = JSON.stringify(params.data_value_json);
      let _q = `mutation {insert_device_config_one(object: {
          ingest_interval: ${params.ingest_interval}, pull_interval: ${params.pull_interval}, 
          signal_source_id: ${params.signal_source.signal_source_id}, data_value_json: ${str_json}, 
          device_id: ${params.device_id}}) 
        {device_config_id}}`;

      if (params.device_config_id) {
        _q = `mutation {update_device_config_by_pk(pk_columns: {
                  device_config_id: ${params.device_config_id}}, _set: {ingest_interval: ${params.ingest_interval}, 
                  pull_interval: ${params.pull_interval}, signal_source_id: ${params.signal_source.signal_source_id}, 
                  data_value_json: ${str_json}, device_id: ${params.device_id}
              }){device_id}}`;
      }

      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_device_config_one ||
          res.data.data.update_device_config_by_pk
        ) {
          commit("getDevice");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del device config,
    async delDeviceConfiguration({ commit }, params) {
      console.log(params);
      await ax
        .post("q", {
          query: `mutation {delete_device_config(where: {device_config_id: {_eq: ${params.device_config_id}}}) {
                returning {device_config_id} }}`
        })
        .then(() => {
          commit("getDevice");
        });
    },
    */

    //--

    //db add group
    async queGroup({ state, commit }, params) {
      let _q = `mutation {insert_group_one(object: {
            name: "${params.name}", owner_id: ${state.user.user_id}
          }){group_id}}`;

      if (params.group_id) {
        _q = `mutation {update_group_by_pk(pk_columns: {
              group_id: ${params.group_id}}, _set: {name: "${params.name}"
          }){group_id}}`;
      }

      await ax.post("q", { query: _q }).then(res => {
        if (
          res.data.data.insert_group_one ||
          res.data.data.update_group_by_pk
        ) {
          commit("getGroup");
        } else {
          return { err: 1 };
        }
      });
    },

    //db del group,
    async delGroup({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_group_by_pk(group_id: ${params.group_id}) {group_id}}`
        })
        .then(res => {
          if (res.data.data.delete_group_by_pk) {
            commit("getGroup");
          } else {
            return { err: 1 };
          }
        });
    },

    //db set group role
    async toggleGroupPerm({ commit }, params) {
      let _q = `mutation { insert_group_role(objects: {
        group_id: ${params.group_id}, role_id: ${params.role_id}}) {
            returning {group_role_id}} }`;

      if (params.value > 0) {
        _q = `mutation { delete_group_role(where: { 
            group_id: {_eq: ${params.group_id}}, role_id: {_eq: ${params.role_id}}}) {
                returning {group_role_id}}
          }`;
      }
      await ax.post("q", { query: _q }).then(() => {
        commit("getGroup");
      });
    },

    //db add group user
    async queGroupUser({ commit }, params) {
      return await ax
        .post("q", {
          query: `{ user(where: {email: {_eq: "${params.email}"}}) { user_id name
                group_users(where: {group_id: {_eq: ${params.group_id}}}) {group_id}
            }}`
        })
        .then(res => {
          if (res.data.data.user.length > 0) {
            //console.log(res.data.data.user[0].group_users.length)
            if (res.data.data.user[0].group_users.length > 0) {
              //kullanıcı zaten gruba kayıtlı
              console.log("Kullanıcı zaten gruba kayıtlı !");
              return { err: 2 };
            } else {
              dax
                .post("q", {
                  query: `mutation {
                        insert_group_user_one(object: {group_id: ${params.group_id}, user_id: ${res.data.data.user[0].user_id}}) {
                          group_user_id
                        }
                      }`
                })
                .then(res => {
                  if (res.data.data.insert_group_user_one) {
                    commit("getGroup");
                  } else {
                    return { err: 1 };
                  }
                });
            }
          } else {
            //kullanıcı kaydı yok
            console.log("Bu email adresine sahip bir kullanıcı bulunamadı !");
            return { err: 3 };
          }
        });
    },

    //db del group user,
    async delGroupUser({ commit }, params) {
      await ax
        .post("q", {
          query: `mutation {delete_group_user(where: {group_id: {_eq: ${params.group_id}}, user_id: {_eq: ${params.user_id}}}) {
                returning {group_user_id} }}`
        })
        .then(() => {
          commit("getGroup");
        });
    },

    //db login
    async queLogin({ state }, params) {
      //let hpass = Base64.stringify(hmacSHA512(params.pass, params.email));
      //var decrypted = CryptoJS.AES.decrypt(encrypted, "IOTP");

      return await ax
        .post("login", {
          email: params.email,
          pass: params.pass
        })
        .then(res => {
          if (res.data && !res.data.err) {
            state.user = res.data;
            ax.defaults.headers["Authorization"] = "IOTP " + state.user.token;
            dax.defaults.headers["Authorization"] = "IOTP " + state.user.token;
          } else {
            return { err: 1 };
          }
        });
    },

    //db signup
    //eslint-disable-next-line
    async queSignup({ state }, params) {
      //let hpass = Base64.stringify(hmacSHA512(params.pass, params.email));
      return await ax
        /* .post("q", {
          query: `mutation { insert_user_one(object: {email: "${params.email}", name: "${params.name}", pass: "${hpass}"}) {
              user_id name email}}`
        }) */
        .post("signup", {
          email: params.email,
          pass: params.pass,
          name: params.name
        })
        .then(res => {
          if (res.data && !res.data.err) {
            state.user = res.data;
          } else {
            return { err: 1 };
          }
        });
    },

    //db forget
    //eslint-disable-next-line
    async queForget({ state }, params) {

      return await ax
        .post("forget", {
          email: params.email,
        })
        .then(res => {
          if (res.data && !res.data.err) {
            return res.data.respass;
          } else {
            return { err: 1 };
          }
        });
        /*
      let respass = Base64.stringify(
        hmacSHA512(params.email, Date.now().toString())
      );
      return await ax
        .post("q", {
          query: `mutation {update_user(_set: {respass: "${respass}"}, where: {email: {_eq: "${params.email}"}}) {returning { 
                  user_id name email respass} }}`
        })
        .then(res => {
          if (res.data.data.update_user.returning.length > 0) {
            return res.data.data.update_user.returning.respass;
            //return respass;
            //state.user = res.data.data.user
          } else {
            return { err: 1 };
          }
        });
        */
    },

    //db reset pass
    //eslint-disable-next-line
    async queResetpass({ state }, params) {
      
      return await ax
        .post("chpass", {
          email: params.email,
          curpass: params.curpass,
          pass: params.pass,
          respass: params.respass
        })
        .then(res => {
          if (res.data && !res.data.err) {
            //return res.data.respass;
          } else {
            return { err: 1 };
          }
        });

      /*
      let opass = Base64.stringify(hmacSHA512(params.curpass, params.email));
      let hpass = Base64.stringify(hmacSHA512(params.pass, params.email));
      
      return await ax
        .post("q", {
          query: `mutation {update_user(_set: {respass: "", pass: "${hpass}"}, where: {email: {_eq: "${params.email}"}, respass: {_eq: "${params.respass}"}, , pass: {_eq: "${opass}"}}) {returning { 
                    user_id name email} }}`
        })
        .then(res => {
          if (res.data.data.update_user.returning.length > 0) {
            //state.user = res.data.data.user
          } else {
            return { err: 1 };
          }
        });
        */
    },

    //db account
    async queAccount({ state }, params) {
      return await ax
        .post("q", {
          query: `mutation { update_user_by_pk(pk_columns: {user_id: ${params.user_id}}, _set: {name: "${params.name}"}) {
                  user_id name email} }`
        })
        .then(res => {
          if (res.data.data.update_user_by_pk) {
            state.user = res.data.data.update_user_by_pk;
          } else {
            return { err: 1 };
          }
        });
    },

    //db account email
    //eslint-disable-next-line
    async queAccountEmail({ state }, params) {
      let opass = Base64.stringify(hmacSHA512(params.curpass, params.email));
      let hpass = Base64.stringify(hmacSHA512(params.curpass, params.newemail));
      return await ax
        .post("q", {
          query: `mutation { update_user(where: {pass: {_eq: "${opass}"}, email: {_eq: "${params.email}"}}, _set: {email: "${params.newemail}", pass: "${hpass}"}) {returning {
                        user_id name email} }}`
        })
        .then(res => {
          if (res.data.data.update_user.returning.length > 0) {
            //state.user = res.data.data.update_user_by_pk
          } else {
            return { err: 1 };
          }
        });
    },

    //db account pass
    //eslint-disable-next-line
    async queAccountPass({ state }, params) {
      let opass = Base64.stringify(hmacSHA512(params.curpass, params.email));
      let hpass = Base64.stringify(hmacSHA512(params.pass, params.email));

      return await ax
        .post("q", {
          query: `mutation { update_user(where: {pass: {_eq: "${opass}"}, email: {_eq: "${params.email}"}}, _set: {pass: "${hpass}"}) {returning {
                  user_id name email} }}`
        })
        .then(res => {
          if (res.data.data.update_user.returning.length > 0) {
            //state.user = res.data.data.update_user_by_pk
          } else {
            return { err: 1 };
          }
        });
    }
  },
  modules: {}
});
