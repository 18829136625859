<script>
export default {
  data() {
    return {
      head_icon: "map-marker-alt",
      show_location_form: false,
      location_table: {
        per_page: 5,
        cur_page: 1,
      },
      location_data: {
        location_id: null,
        name: "",
        state: "",
        address: "",
        status: 1,
        company: {
          company_id: null,
        },
        city: {
          city_id: null,
          country: {
            country_id: null,
          },
        },
      },
      location_company_id: this.$route.params.company_id || "",
    };
  },
  methods: {
    toggleLocationForm() {
      this.clearLocationForm();
      this.show_location_form = !this.show_location_form;
    },
    clearLocationForm() {
      this.location_data = {
        location_id: null,
        name: "",
        state: "",
        address: "",
        status: 1,
        company: {
          company_id: null,
        },
        city: {
          city_id: null,
          country: {
            country_id: null,
          },
        },
      };
    },
    //--
    submitLocation() {
      this.$store.dispatch("queLocation", this.location_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln["msg_error"], "danger");
        } else {
          this.show_location_form = false;
          this.showMsg(this.ln["msg_success"]);
          this.$store.commit("getLocation");
        }
      });
      this.clearLocationForm();
    },
    deleteLocation(_location) {
      this.$bvModal
        .msgBoxConfirm(this.ln["msg_confirm"], {
          ...this.$store.state.modal_opt,
          title: this.ln["confirm_title"],
          okTitle: this.ln["btn_ok"],
          cancelTitle: this.ln["btn_cancel"],
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("delLocation", _location).then((res) => {
              if (res && res.err) {
                this.showMsg(this.ln["msg_error"], "danger");
              } else {
                this.showMsg(this.ln["msg_success"]);
                this.$store.commit("getLocation");
              }
            });
          }
        });
    },
    editLocation(_location) {
      this.clearLocationForm();
      this.location_data = _location;
      this.show_location_form = true;
    },
  },
  computed: {
    location_company() {
      let ret = null;
      if (this.location_company_id && this.$store.state.company) {
        ret = this.$store.state.company.find((e) => {
          return e.company_id == this.location_company_id;
        });
      }
      return ret;
    },
    arr_location() {
      let ret = [];
      if (this.$store.state.location) {
        ret = this.$store.state.location;
        if (this.location_company_id) {
          ret = this.$store.state.location.filter((e) => {
            return e.company.company_id == this.location_company_id;
          });
        }
      }
      return ret;
    },
    location_len() {
      return this.arr_location.length || 0;
    },
    show_location_pager() {
      return this.location_len > this.location_table.per_page;
    },
    location_edit() {
      return this.location_data.location_id ? 1 : 0;
    },
    arr_cities() {
      let _ = this;
      let _country = this.$store.state.country.find((e) => {
        return e.country_id == _.location_data.city.country.country_id;
      });
      return _country ? _country.cities : [];
    },
  },
  mounted() {
    this.$store.commit("getCountry");
    this.$store.commit("getCompany");
    this.$store.commit("getLocation");
  },
};
</script>

<template>
  <main class="content">
    <div
      :class="
        'pricing-header px-3 pb-3 pb-md-5 mx-auto text-center ' +
        (arr_location.length > 0 ? 'mini' : '')
      "
    >
      <fa-icons class="picon mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln["locations_title"] }}</h1>
      <p class="lead">
        <small>{{ ln["locations_desc"] }}</small>
      </p>
      <button
        class="btn btn-dark"
        @click="toggleLocationForm"
        :disabled="show_location_form"
      >
        <fa-icons icon="plus" />
        {{ ln["btn_addnew"] }}
      </button>
    </div>

    <div>
      <div class="row">
        <div class="col-12 col-xl">
          <section class="widget pb-0">
            <header>
              <h4>
                <b>{{
                  location_company ? location_company.name + " - " : ""
                }}</b>
                {{ ln["location_list"] }}
              </h4>
              <div class="widget-controls full">
                <b-form inline>
                  <label class="mr-sm-2 font-weight-bold"
                    >{{ ln["company"] }} :</label
                  >
                  <b-form-select
                    size="sm"
                    v-model="location_company_id"
                    @change="
                      (value) => {
                        $router.push({ path: '/location/' + value });
                      }
                    "
                    :options="$store.state.company"
                    value-field="company_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option value>{{
                        ln["form_showall"]
                      }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form>
              </div>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                striped
                :items="arr_location"
                :fields="[
                  {
                    key: 'name',
                    label: ln['form_locationname'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  {
                    key: 'country',
                    label: ln['form_country'],
                  },
                  {
                    key: 'city',
                    label: ln['form_city'] + ', ' + ln['form_state'],
                  },
                  {
                    key: 'gateways',
                    label: ln['gateways_title'],
                    class: 'text-center',
                  },
                  {
                    key: 'status',
                    label: ln['form_status'],
                    class: 'text-center',
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
                :per-page="location_table.per_page"
                :current-page="location_table.cur_page"
              >
                <template v-slot:cell(country)="row">{{
                  row.item.city.country.name
                }}</template>

                <template v-slot:cell(city)="row"
                  >{{ row.item.city.name }}, {{ row.item.state }}</template
                >

                <template v-slot:cell(gateways)="row">
                  <router-link
                    class="btn btn-xs font-weight-bold bg-warning"
                    :to="`/gateway/${row.item.location_id}`"
                  >
                    {{ row.item.gateways.length }}
                    {{ ln["gateway"] }}</router-link
                  >
                </template>

                <template v-slot:cell(status)="row">
                  <b-button
                    size="xs"
                    :variant="row.item.status ? 'success' : 'danger'"
                    class="px-2 font-weight-normal"
                    @click="$store.dispatch('toggleLocationStatus', row.item)"
                  >
                    {{ ln["form_" + (row.item.status ? "active" : "passive")] }}
                  </b-button>
                </template>

                <template v-slot:cell(action)="row">
                  <b-button
                    size="xs"
                    variant="info"
                    @click="editLocation(row.item)"
                  >
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button
                    size="xs"
                    variant="danger"
                    class="ml-2"
                    @click="deleteLocation(row.item)"
                  >
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

            <footer class="pb-1">
              <b-pagination
                v-show="show_location_pager"
                v-model="location_table.cur_page"
                :total-rows="location_len"
                :per-page="location_table.per_page"
                align="center"
              ></b-pagination>
              <p class="text-center text-info" v-show="!location_len">
                {{ ln["msg_empty"] }}
              </p>
            </footer>
          </section>
        </div>

        <div
          class="col-12 col-xl-4 order-first order-xl-last"
          v-if="show_location_form"
        >
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln["location_" + (location_edit ? "edit" : "new")] }}
              </h4>
              <div class="widget-controls">
                <a href @click.prevent="toggleLocationForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitLocation()">
                <b-form-group :label="ln['company']">
                  <b-form-select
                    v-model="location_data.company.company_id"
                    :options="$store.state.company"
                    value-field="company_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        {{ ln["form_selectone"] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_locationname']">
                  <b-form-input
                    v-model="location_data.name"
                    required
                    placeholder="e.g TrexDCAS"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_country']">
                  <b-form-select
                    v-model="location_data.city.country.country_id"
                    :options="$store.state.country"
                    value-field="country_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        {{ ln["form_selectone"] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_city']">
                  <b-form-select
                    v-model="location_data.city.city_id"
                    :options="arr_cities"
                    value-field="city_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        {{ ln["form_selectone"] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_state']">
                  <b-form-input
                    v-model="location_data.state"
                    required
                    placeholder="e.g Özlüce"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['form_address']">
                  <b-form-textarea
                    rows="2"
                    v-model="location_data.address"
                    required
                  ></b-form-textarea>
                </b-form-group>

                <b-button type="submit" variant="dark">
                  {{ ln["btn_" + (location_edit ? "update" : "create")] }}
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
