<script>
export default {
  data() {
    return {
      head_icon: 'briefcase',
      head_title: 'Companies',
      head_desc:
        "Quickly build an effective pricing table for your potential customers with this Bootstrap example. It's built with default Bootstrap components.",
      widget_title: 'Company',
      addNew: 0,
      perPage: 5,
      currentPage: 1,
      fields: [
        {
          key: 'name',
          sortable: true,
          isRowHeader: true,
          thClass: 'pl-4',
          tdClass: 'pl-4',
        },
        {
          key: 'country',
          //sortable: true,
        },
        /* {
          key: 'icode',
          label: 'Industry Code',
        }, */
        { key: 'location', class: 'text-center' },
        { key: 'status', class: 'text-center' },
        { key: 'action', thClass: 'pr-4 text-right', tdClass: 'pr-4 text-right' },
      ],
      items: [
        {
          name: 'TrexDCAS',
          country: 'Turkey',
          location: 1,
          icode: '-',
          status: 1,
        },
        {
          name: 'CocaCola',
          country: 'United States',
          location: 5,
          icode: '424490',
          status: 1,
        },
        {
          name: 'Microsoft',
          country: 'United States',
          location: 2,
          icode: '561990',
          status: 0,
        },
        {
          name: 'Google',
          country: 'United States',
          location: 8,
          icode: '518210',
          status: 1,
        },
      ],
      form: {
        name: '',
        country: null,
        icode: '',
      },
      countries: [
        { text: 'Select One', value: null },
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'The Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cabo Verde',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo, Democratic Republic of the',
        'Congo, Republic of the',
        'Costa Rica',
        'Côte d’Ivoire',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor (Timor-Leste)',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Eswatini',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'The Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Korea, North',
        'Korea, South',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia, Federated States of',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar (Burma)',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'North Macedonia',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Sudan, South',
        'Suriname',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe',
      ],
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
  },
}
</script>

<template>
  <main class="content">
    <div class="pricing-header px-3 pb-3 pb-md-5 mx-auto text-center">
      <fa-icons class="mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ head_title }}</h1>
      <p class="lead">
        <small>{{ head_desc }}</small>
      </p>
      <button class="btn btn-dark" @click="addNew = !addNew" :disabled="Boolean(addNew)">
        <fa-icons icon="plus" />
        Add New
      </button>
    </div>

    <div class="company">
      <div class="row">
        <div class="col-12 col-md">
          <section class="widget pb-0">
            <header>
              <h4>{{ widget_title }} <strong>List</strong></h4>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                id="btable"
                striped
                hover
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template v-slot:cell(location)="row">
                  <b-button
                    size="xs"
                    variant="warning"
                    class="px-2 font-weight-bold"
                    @click="info(row.item, row.index, $event.target)"
                  >
                    {{ row.item.location }} location
                  </b-button>
                </template>

                <template v-slot:cell(status)="row">
                  <b-badge
                    class="font-weight-normal p-1"
                    :variant="row.item.status > 0 ? 'success' : 'danger'"
                  >
                    Active
                  </b-badge>
                </template>

                <template v-slot:cell(action)="row">
                  <b-button size="xs" variant="info" @click="info(row.item, row.index, $event.target)">
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button
                    size="xs"
                    variant="danger"
                    class="ml-2"
                    @click="info(row.item, row.index, $event.target)"
                  >
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>
            <footer class="pb-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="ptable"
                align="center"
              ></b-pagination>
            </footer>
          </section>
        </div>

        <div class="col-12 col-md-4 order-first order-md-last" v-if="addNew">
          <section class="widget pb-0">
            <header>
              <h4><strong>New</strong> {{ widget_title }}</h4>
              <div class="widget-controls">
                <a href="" @click.prevent="addNew = !addNew" class="text-dark"><fa-icons icon="times"/></a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form>
                <b-form-group label="Company name">
                  <b-form-input v-model="form.name" required placeholder="e.g TrexDCAS"></b-form-input>
                </b-form-group>

                <b-form-group label="Country">
                  <b-form-select v-model="form.country" :options="countries" required></b-form-select>
                </b-form-group>

                <b-form-group label="Industry code">
                  <b-form-input v-model="form.icode" required placeholder="e.g 555111"></b-form-input>
                </b-form-group>

                <b-button type="submit" variant="dark">
                  Create
                </b-button>
              </b-form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
