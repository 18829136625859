<script>
import FlagsBar from '@/components/FlagsBar'

export default {
  components: {
    FlagsBar,
  },
  data() {
    return {
      visitor_screens: ['Login', 'Signup', 'Forget', 'Reset'],
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout')
      this.$router.push({ path: '/login' })
    },
  },
  computed: {
    user_login() {
      return this.$store.state.user !== null ? true : false
    },
  },
  created() {
    if (!this.user_login && !this.visitor_screens.includes(this.$router.currentRoute.name)) {
      this.$router.push({ path: '/login' })
    }
    if (this.user_login && this.visitor_screens.includes(this.$router.currentRoute.name)) {
      this.$router.push({ path: '/' })
    }
  },
}
</script>

<template>
  <div class="home h-100">
    <nav id="sidebar" class="sidebar" v-if="this.$store.state.user">
      <header class="logo d-none d-md-block">
        <router-link to="/"><img src="../assets/logo2.svg" alt="logo" class="logo"/></router-link>
      </header>
      <ul class="sidebar-nav">
        <li>
          <router-link to="/">
            <span class="icon"><fa-icons icon="chart-pie"/></span>
            {{ ln['home'] }}
            <!--<span class="badge badge-warning">3</span>-->
          </router-link>
        </li>
        <li>
          <router-link to="/dashboard">
            <span class="icon"><fa-icons icon="desktop" /> </span>
            {{ ln['dashboards_title'] }}
          </router-link>
        </li>
        <li>
          <router-link to="/company">
            <span class="icon"><fa-icons icon="briefcase"/></span>
            {{ ln['companies_title'] }}
          </router-link>
        </li>
        <li>
          <router-link to="/location">
            <span class="icon"><fa-icons icon="map-marker-alt"/></span>
            {{ ln['locations_title'] }}
          </router-link>
        </li>
        <li>
          <router-link to="/gateway">
            <span class="icon"><fa-icons icon="satellite-dish" /> </span>
            {{ ln['gateways_title'] }}
          </router-link>
        </li>
        <li>
          <router-link to="/device">
            <span class="icon"><fa-icons icon="plug" /> </span>
            {{ ln['devices_title'] }}
          </router-link>
        </li>
        <li>
          <router-link to="/source">
            <span class="icon"><fa-icons icon="anchor" /> </span>
            {{ ln['sources_title'] }}
          </router-link>
        </li>
        <li>
          <router-link to="/monitore">
            <span class="icon"><fa-icons icon="chart-bar" /> </span>
            {{ ln['monitores_title'] }}
          </router-link>
        </li>
        <li>
          <router-link to="/analyst">
            <span class="icon"><fa-icons icon="chalkboard-teacher" /> </span>
            {{ ln['analysts_title'] }}
          </router-link>
        </li>
        <!--
        <li>
          <router-link to="/grafana" class="grafana">
            <span class="icon"><img src="http://localhost:1560/public/img/grafana_icon.svg"/></span>
            Grafana
          </router-link>
        </li>
        -->
        <li>
          <a href="http://localhost:1560" target="_blank" class="grafana">
            <span class="icon"><img src="http://localhost:1560/public/img/grafana_icon.svg"/></span>
            Grafana
          </a>
        </li>
        <li class="active show">
          <router-link to="/account" data-toggle="collapse" data-parent="#sidebar">
            <span class="icon"><fa-icons icon="user-cog"/></span>
            {{ ln['settings_title'] }}
          </router-link>
          <ul id="settings" class="collapse show">
            <!-- <li><router-link to="/settings">General</router-link></li> -->
            <li>
              <router-link to="/account">{{ ln['account_title'] }}</router-link>
            </li>
            <li>
              <a @click.prevent="logout" href="#">{{ ln['btn_logout'] }}</a>
            </li>
          </ul>
        </li>
      </ul>
      <FlagsBar />
    </nav>
    <nav class="page-controls navbar navbar-light bg-light">
      <a class="d-lg-none nav-link" id="nav-collapse-toggle" href="#">
        <fa-icons icon="bars"></fa-icons>
      </a>
      bb
    </nav>
    <div :class="`content-wrap ${user_login ? '' : 'ml-0'}`">
      <router-view />
    </div>
  </div>
</template>
