<script>
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  props: ['watched_source'],
  components: {
    VueSvgGauge,
  },
  data() {
    return {
      watch_timer: null,
      watch_data: {
        data: [
          {
            key: '',
            value: '',
            time: '',
          },
        ],
      },
      watched_config: '',
      chart_data: null,
      perValue: 0,
    }
  },
  methods: {
    fillChart() {
      /*
      if (this.watch_data.length > 0) {
        this.chart_data = JSON.parse(this.watch_data[0])
        let min = this.watched_config.min //this.chart_data.min
        let max = this.watched_config.max //this.chart_data.max
        this.perValue = (((this.chart_data.value - min) * 10) / (max - min)).toFixed(2)
      }
      */
      let _datasets = []
      let _colors = ['#f44336', '#17a2b8', '#4caf50', '#673ab7', '#fbb031', '#795548', '#607d8b']
      this.watch_data.forEach((sensor, i) => {
        console.log(sensor)
        if (sensor.values && sensor.values.length > 0) {
          let _data = sensor.values
            .map((data) => {
              return { x: JSON.parse(data).time, y: JSON.parse(data).value, filter: JSON.parse(data).filter }
            })
            .reverse()

          _datasets.push({
            label: sensor.key,
            borderColor: _colors[i],
            data: _data,
          })
        }
      })

      this.chart_data = _datasets
    },
    watchHandler(_monitore) {
      this.$store.dispatch('monitorValue', _monitore).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          this.watch_data = res.data[0].values
          this.fillChart()
        }
      })
    },
    beginWatch(_monitore) {
      this.endWatch()
      let _interval = _monitore.source.device.gateway.pull_interval
      if (_monitore.source.pull_interval) {
        _interval = _monitore.source.pull_interval
      } else if (_monitore.source.device.pull_interval) {
        _interval = _monitore.source.device.pull_interval
      }

      console.log(`${_monitore.source.name} is watching every ${_interval} ms`)

      if (_interval > 0) {
        this.watchHandler(_monitore)
        this.watch_timer = setInterval(() => {
          this.watchHandler(_monitore)
        }, _interval)
      }
    },
    endWatch() {
      console.log(`End watching..`)
      clearInterval(this.watch_timer)
      this.watch_timer = null
    },
  },
  watch: {
    //eslint-disable-next-line
    watched_source: function(newVal, oldVal) {
      this.watch_data = newVal
      this.fillChart()
    },
  },
  mounted() {
    //console.log(this.watched_source)
    if (this.watched_source.data_value_json) {
      this.watched_config = JSON.parse(this.watched_source.data_value_json)
    }
    //this.beginWatch(this.watched_source)
    this.watch_data = this.watched_source
    this.fillChart()
  },
  computed: {},
  destroyed() {
    this.endWatch()
  },
}
</script>

<template>
  <div class="row no-gutters">
    <div class="col-md-3 p-2" v-for="sensor in this.chart_data" :key="sensor.label">
      <div class="border rounded pt-3 px-3 pb-2 text-center">
        <div class="chspeed">
          <VueSvgGauge
            :start-angle="-70"
            :end-angle="70"
            :value="(((sensor.data[0].y - 0) * 10) / (100 - 0)).toFixed(2)"
            :min="0"
            :max="10"
            :gauge-color="[{ offset: 100, color: sensor.borderColor }]"
          />
          <span>{{ sensor.data ? sensor.data[0].y.toFixed(2) : '-' }}</span>
          <h6 class="mt-3">
            {{ sensor.label }}
          </h6>
          <h6 class="mt-3">
            <small>
              <fa-icons icon="clock" />
              {{ sensor.data ? frmDate(sensor.data[0].x) : '-' }}
            </small>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.chspeed {
  position: relative;
  top: 10px;
}
.chspeed span {
  position: absolute;
  bottom: 5rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
}
</style>
