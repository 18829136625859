var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HorizontalBarChart',{attrs:{"chart-data":this.chart_data || {},"options":{
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: true },
      scales: {
        yAxes: [
          {
            display: true,
            //ticks: this.chart_minmax,
          } ],
        xAxes: [{ display: true, ticks: { display: true } }],
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }