<script>
export default {
  components: {},
  computed: {
    arr_dashboard() {
      return this.$store.state.dashboard || []
    },
  },
  mounted() {
    this.$store.commit('getDashboard')
  },
  destroyed() {},
}
</script>

<template>
  <div>
    <div class="pricing-header px-3 pb-3 pb-md-5 mx-auto text-center">
      <h1 class="display-4">{{ ln['dashboards_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['dashboards_desc'] }}</small>
      </p>
    </div>

    <div class="gateway-widget-list">
      <div class="row">
        <div
          :class="`col-xl-3 col-md-6 mb-4 mb-xl-0`"
          v-for="(dashboard, i) in arr_dashboard"
          :key="`dash_${i}`"
        >
          <div class="pb-xl-4 h-100">
            <section class="widget mb-0 h-100 text-center">
              <header>
                <fa-icons class="mt-3 mb-2" icon="desktop" size="2x" />
                <h5>{{ dashboard.name }}</h5>
                <span>{{ dashboard.monitores.length }} {{ ln['monitor'] }}</span>
                <div class="widget-controls">
                  <a data-widgster="close" title="Close" href="#"><i class="la la-remove"></i></a>
                </div>
              </header>
              <div class="widget-body">
                <router-link
                  class="btn btn-xs text-white bg-success"
                  :to="`/dashboard/${dashboard.dashboard_id}`"
                >
                  <fa-icons icon="paint-brush" />
                  {{ ln['form_design'] }}
                </router-link>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
