<script>
export default {
  methods: {
    changeLang(_lang) {
      document.documentElement.lang = _lang
      this.$store.state.lang = _lang
    },
    langActive(_lang) {
      return this.$store.state.lang == _lang ? 'info' : ''
    },
  },
}
</script>

<template>
  <div class="d-flex justify-content-center mt-2 lang_links">
    <b-button @click.prevent="changeLang('tr')" size="xs" :variant="langActive('tr')">TR</b-button>
    <b-button @click.prevent="changeLang('en')" class="ml-2" size="xs" :variant="langActive('en')"
      >EN</b-button
    >
  </div>
</template>
