import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Grafana from "@/views/Grafana.vue";
import Dashboard from "@/views/Dashboard.vue";
import Design from "@/views/Design.vue";
import Company from "@/views/Company.vue";
import Location from "@/views/Location.vue";
import Gateway from "@/views/Gateway.vue";
import Device from "@/views/Device.vue";
import Source from "@/views/Source.vue";
import Monitore from "@/views/Monitore.vue";
import Settings from "@/views/Settings.vue";
import Account from "@/views/Account.vue";
import Analyst from "@/views/Analyst.vue";
import Login from "@/views/Login.vue";
import Signup from "@/views/Signup.vue";
import Forget from "@/views/Forget.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Home
  },
  {
    path: "/grafana",
    name: "Grafana",
    component: Grafana
  },
  {
    path: "/dashboard",
    name: "Dashes",
    component: Dashboard
  },
  {
    path: "/dashboard/:dashboard_id",
    component: Design
  },
  {
    path: "/monitore",
    name: "Monitore",
    component: Monitore
  },
  {
    path: "/monitore/:dashboard_id",
    component: Monitore
  },
  {
    path: "/company",
    name: "Company",
    component: Company
  },
  {
    path: "/location",
    name: "Location",
    component: Location
  },
  {
    path: "/location/:company_id",
    component: Location
  },
  {
    path: "/gateway",
    name: "Gateway",
    component: Gateway
  },
  {
    path: "/gateway/:location_id",
    component: Gateway
  },
  {
    path: "/device",
    name: "Device",
    component: Device
  },
  {
    path: "/device/:gateway_id",
    component: Device
  },
  {
    path: "/source",
    name: "Source",
    component: Source
  },
  {
    path: "/source/:device_id",
    component: Source
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/account",
    name: "Account",
    component: Account
  },
  {
    path: "/analyst",
    name: "Analyst",
    component: Analyst
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup
  },
  {
    path: "/forget",
    name: "Forget",
    component: Forget
  },
  {
    path: "/forget/:respass",
    name: "Reset",
    component: Forget
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
