<script>
import WidgetOnoff2 from '@/components/WidgetOnoff2'
import WidgetLine3 from '@/components/WidgetLine3'
import WidgetDonut2 from '@/components/WidgetDonut2'
import WidgetDefault2 from '@/components/WidgetDefault2'
import WidgetBars2 from '@/components/WidgetBars2'
import WidgetProgress2 from '@/components/WidgetProgress2'
import WidgetSpeed2 from '@/components/WidgetSpeed2'

export default {
  components: {
    WidgetOnoff2, //eslint-disable-line
    WidgetLine3, //eslint-disable-line
    WidgetDonut2, //eslint-disable-line
    WidgetDefault2, //eslint-disable-line
    WidgetBars2, //eslint-disable-line
    WidgetProgress2, //eslint-disable-line
    WidgetSpeed2, //eslint-disable-line
  },
  data() {
    return {
      head_icon: 'chart-bar',
      show_monitore_form: false,
      show_watch_form: false,
      monitore_table: {
        per_page: 5,
        cur_page: 1,
      },
      monitore_data: {
        name: '',
        monitore_id: null,
        pin: false,
        size: 1,
        data_value_json: '',
        query: '',
        dashboard: {
          dashboard_id: null,
          name: '',
        },
        chart: {
          chart_id: null,
          name: '',
        },
        source: {
          source_id: null,
          name: '',
          slug: '',
          pull_interval: null,
          ingest_interval: null,
          device: {
            device_id: null,
            name: '',
            pull_interval: null,
            ingest_interval: null,
            gateway: {
              gateway_id: null,
              name: '',
              pull_interval: 0,
              ingest_interval: 0,
            },
          },
        },
      },
      /* watch_data: {
        data: [
          {
            key: "",
            value: "",
            time: "",
          },
        ],
      },
      watched_source: "",
      watched_config: "",
      chart_data: null, */
      modal_json: {},
      config_json: {},
      monitore_dashboard_id: this.$route.params.dashboard_id || '',

      filter_company: [],
      filter_location: [],
      filter_gateway: [],
      filter_device: [],
      query_result: [],
    }
  },
  methods: {
    closeWatchForm() {
      this.show_watch_form = false
      //this.endWatch();
    },
    toggleMonitoreForm() {
      this.clearMonitoreForm()
      this.show_monitore_form = !this.show_monitore_form
    },
    clearMonitoreForm() {
      this.filter_company = []
      this.filter_location = []
      this.filter_gateway = []
      this.filter_device = []
      this.query_result = []

      this.monitore_data = {
        monitore_id: null,
        pin: false,
        data_value_json: '',
        dashboard: {
          dashboard_id: null,
          name: '',
        },
        chart: {
          chart_id: null,
          name: '',
        },
        source: {
          source_id: null,
          name: '',
          slug: '',
          pull_interval: null,
          ingest_interval: null,
          device: {
            device_id: null,
            name: '',
            pull_interval: null,
            ingest_interval: null,
            gateway: {
              gateway_id: null,
              name: '',
              pull_interval: 0,
              ingest_interval: 0,
            },
          },
        },
      }
    },
    //--
    submitMonitore() {
      this.monitore_data.query = JSON.stringify({
        company: this.filter_company,
        location: this.filter_location,
        gateway: this.filter_gateway,
        device: this.filter_device,
      })
      this.monitore_data.data_value_json = JSON.stringify({
        branch: this.filter_device.map((branch) => `device-${branch}`),
        slug: null,
        limit: 10,
      })
      this.$store.dispatch('queMonitore', this.monitore_data).then((res) => {
        if (res && res.err) {
          this.showMsg(this.ln['msg_error'], 'danger')
        } else {
          this.show_monitore_form = false
          this.showMsg(this.ln['msg_success'])
          this.$store.commit('getMonitore')
        }
      })
      this.clearMonitoreForm()
    },
    deleteMonitore(_monitore) {
      this.$bvModal
        .msgBoxConfirm(this.ln['msg_confirm'], {
          ...this.$store.state.modal_opt,
          title: this.ln['confirm_title'],
          okTitle: this.ln['btn_ok'],
          cancelTitle: this.ln['btn_cancel'],
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch('delMonitore', _monitore).then((res) => {
              if (res && res.err) {
                this.showMsg(this.ln['msg_error'], 'danger')
              } else {
                this.showMsg(this.ln['msg_success'])
                this.$store.commit('getMonitore')
              }
            })
          }
        })
    },
    editMonitore(_monitore) {
      this.clearMonitoreForm()
      this.monitore_data = _monitore
      if (this.monitore_data.chart == null) {
        this.monitore_data.chart = { chart_id: null, name: '' }
      }
      if (this.monitore_data.dashboard == null) {
        this.monitore_data.dashboard = { dashboard_id: null, name: '' }
      }
      if (this.monitore_data.query !== null) {
        let query = JSON.parse(this.monitore_data.query) || []
        console.log(query)
        this.filter_company = query.company
        this.filter_location = query.location
        this.filter_gateway = query.gateway
        this.filter_device = query.device
      }

      this.show_monitore_form = true

      this.changeConfigurationChart(false)
    },
    changeConfigurationChart(_change) {
      _change = _change == undefined ? true : _change
      this.config_json = {}
      this.modal_json = {}
      let _chart = null

      if (this.monitore_data.chart.chart_id) {
        _chart = this.arr_chart.find((chart) => {
          return chart.chart_id == this.monitore_data.chart.chart_id
        })
      }

      if (_chart) {
        _chart.chart_configs.map((e) => {
          this.config_json[e.slug] = ''

          this.modal_json[e.slug] = ''
          if (e.type) {
            let _json_select = this.$store.state.chart.find((chart) => {
              return chart.chart_id == e.type
            })

            this.modal_json[e.slug] = _json_select.chart_configs
          }
        })
      }

      if (_change) {
        let json_str = ''
        if (Object.keys(this.config_json).length > 0) {
          json_str = JSON.stringify(this.config_json)
        }
        this.monitore_data.data_value_json = json_str
      }
    },
    getConfigurationJsonString() {
      //console.log(this.config_json);
      this.monitore_data.data_value_json = JSON.stringify(this.config_json)
    },
    /* fillChart() {
      this.chart_data = {
        labels: this.watch_data.data.map((e) => this.frmDate(e.time)).reverse(),
        datasets: [
          {
            barPercentage: 0.7,
            backgroundColor: "#f44336",
            data: this.watch_data.data.map((e) => e.value).reverse(),
          },
        ],
      };
    }, */
    watchSource(_monitore) {
      this.clearMonitoreForm()
      this.watched_source = _monitore
      /*
      if (_monitore.data_value_json) {
        this.watched_config = JSON.parse(_monitore.data_value_json);
      }
      */

      this.show_monitore_form = false
      this.show_watch_form = true
      //this.beginWatch(_monitore);
    },
    /* watchHandler(_monitore) {
      this.$store
        .dispatch("monitorValue", _monitore)
        .then((res) => {
          if (res && res.err) {
            //this.showMsg(this.ln["msg_error"], "danger");
          } else {
            //console.log(res);
            this.watch_data = res;
            this.fillChart();
          }
        });
    },
    beginWatch(_monitore) {
      this.endWatch();
      let _interval = _monitore.source.device.gateway.pull_interval;
      if (_monitore.source.pull_interval) {
        _interval = _monitore.source.pull_interval;
      } else if (_monitore.source.device.pull_interval) {
        _interval = _monitore.source.device.pull_interval;
      }

      console.log(`${_monitore.source.name} is watching every ${_interval} ms`);

      if (_interval > 0) {
        this.watchHandler(_monitore);
        this.$store.state.watch_timer = setInterval(() => {
          this.watchHandler(_monitore);
        }, _interval);
      }
    },
    endWatch() {
      console.log(`End watching..`);
      clearInterval(this.$store.state.watch_timer);
      this.$store.state.watch_timer = null;
    }, */
    run_query() {
      //console.log(this.filter_device)
      this.query_result = this.filter_device.map((branch) => `device-${branch}`)
      let req = { branch: this.filter_device.map((branch) => `device-${branch}`), slug: null, limit: 10 }
      this.$store.dispatch('getKeyValue', req).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res.data)
          this.query_result = res.data
          //this.watch_data = res.data[0].values
          //this.fillChart()
        }
      })
    },
  },
  filters: {
    pretty: function(value) {
      if (value) {
        return JSON.stringify(JSON.parse(value), null, 2)
      }
    },
  },
  computed: {
    monitore_dashboard() {
      let ret = null
      if (this.monitore_dashboard_id && this.$store.state.dashboard) {
        ret = this.$store.state.dashboard.find((e) => {
          return e.dashboard_id == this.monitore_dashboard_id
        })
      }
      return ret
    },
    arr_chart() {
      let ret = []
      //ret = this.$store.state.chart
      ret = this.$store.state.chart.filter((e) => {
        return e.status && e.status > 0
      })
      return ret || []
    },
    arr_monitore() {
      let ret = []
      if (this.$store.state.monitore) {
        ret = this.$store.state.monitore

        if (this.monitore_dashboard_id) {
          ret = this.$store.state.monitore.filter((e) => {
            return e.dashboard && e.dashboard.dashboard_id == this.monitore_dashboard_id
          })
        }
      }
      return ret
    },
    monitore_len() {
      return this.arr_monitore.length || 0
    },
    show_monitore_pager() {
      return this.monitore_len > this.monitore_table.per_page
    },
    monitore_edit() {
      return this.monitore_data.monitore_id ? 1 : 0
    },
    arr_sources() {
      let _ = this
      let _device = this.$store.state.device.find((e) => {
        return e.device_id == _.monitore_data.source.device.device_id
      })
      return _device ? _device.sources : []
    },
    /* statusIcon() {
      let ret = "arrows-alt-h";
      if (
        this.watch_data.data[1] &&
        this.watch_data.data[0].value > this.watch_data.data[1].value
      ) {
        ret = "arrow-up";
      } else if (
        this.watch_data.data[1] &&
        this.watch_data.data[0].value < this.watch_data.data[1].value
      ) {
        ret = "arrow-down";
      }
      return ret;
    }, */
    arr_branch_device() {
      let ret = []
      if (this.$store.state.branch) {
        ret = this.$store.state.branch
      }
      return ret
    },
    arr_branch() {
      let ret = []
      if (this.arr_branch_device) {
        this.arr_branch_device.forEach((device) => {
          let _device = {
            device_id: device.device_id,
            name: device.name,
          }
          if (
            this.filter_company.indexOf(device.gateway.location.company.company_id) > -1 &&
            this.filter_location.indexOf(device.gateway.location.location_id) > -1 &&
            this.filter_gateway.indexOf(device.gateway.gateway_id) > -1
          ) {
            ret.push(_device)
          }
        })
      }
      return ret
    },
    arr_branch_company() {
      let ret = []
      if (this.arr_branch_device) {
        this.arr_branch_device.forEach((device) => {
          let company = {
            company_id: device.gateway.location.company.company_id,
            name: device.gateway.location.company.name,
          }
          if (
            !ret.find((e) => {
              return e.company_id == company.company_id
            })
          ) {
            ret.push(company)
          }
        })
      }
      return ret
    },
    arr_branch_location() {
      let ret = []
      if (this.arr_branch_device) {
        this.arr_branch_device.forEach((device) => {
          let location = {
            location_id: device.gateway.location.location_id,
            name: device.gateway.location.name,
          }
          if (
            !ret.find((e) => {
              return e.location_id == location.location_id
            }) &&
            this.filter_company.indexOf(device.gateway.location.company.company_id) > -1
          ) {
            ret.push(location)
          }
        })
      }
      return ret
    },
    arr_branch_gateway() {
      let ret = []
      if (this.arr_branch_device) {
        this.arr_branch_device.forEach((device) => {
          let gateway = {
            gateway_id: device.gateway.gateway_id,
            name: device.gateway.name,
          }
          if (
            !ret.find((e) => {
              return e.gateway_id == gateway.gateway_id
            }) &&
            this.filter_company.indexOf(device.gateway.location.company.company_id) > -1 &&
            this.filter_location.indexOf(device.gateway.location.location_id) > -1
          ) {
            ret.push(gateway)
          }
        })
      }
      return ret
    },
  },
  watch: {
    /*
    filter_company: function() {
      this.filter_location = this.arr_branch_location.map((location) => location.location_id)
    },
    filter_location: function() {
      this.filter_gateway = this.arr_branch_gateway.map((gateway) => gateway.gateway_id)
    },
    filter_gateway: function() {
      this.filter_device = this.arr_branch.map((device) => device.device_id)
    },
    */
  },
  mounted() {
    this.$store.commit('getChart')
    this.$store.commit('getDevice')
    this.$store.commit('getMonitore')
    this.$store.commit('getDashboard')
    this.$store.commit('getBranch')
  },
  destroyed() {
    //this.endWatch();
  },
}
</script>

<template>
  <main class="content">
    <div
      :class="
        'pricing-header px-3 pb-3 pb-md-5 mx-auto text-center ' + (arr_monitore.length > 0 ? 'mini' : '')
      "
    >
      <fa-icons class="picon mb-4" :icon="head_icon" size="5x" />
      <h1 class="display-4">{{ ln['monitores_title'] }}</h1>
      <p class="lead">
        <small>{{ ln['monitores_desc'] }}</small>
      </p>
      <button class="btn btn-dark" @click="toggleMonitoreForm" :disabled="show_monitore_form">
        <fa-icons icon="plus" />
        {{ ln['btn_addnew'] }}
      </button>
    </div>

    <div>
      <div class="row">
        <div class="col-12 col-xl">
          <section class="widget pb-0">
            <header>
              <h4>
                <b>{{ monitore_dashboard ? monitore_dashboard.name + ' - ' : '' }}</b>
                {{ ln['monitore_list'] }}
              </h4>
              <div class="widget-controls full">
                <b-form inline>
                  <label class="mr-sm-2 font-weight-bold">{{ ln['dashboard'] }} :</label>
                  <b-form-select
                    size="sm"
                    v-model="monitore_dashboard_id"
                    @change="
                      (value) => {
                        $router.push({ path: '/monitore/' + value })
                      }
                    "
                    :options="$store.state.dashboard"
                    value-field="dashboard_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option value>{{ ln['form_showall'] }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form>
              </div>
            </header>
            <div class="widget-body p-0">
              <b-table
                responsive
                striped
                :items="arr_monitore"
                :fields="[
                  {
                    key: 'name',
                    label: ln['monitor'],
                    isRowHeader: true,
                    sortable: true,
                    thClass: 'pl-4',
                    tdClass: 'pl-4',
                  },
                  {
                    key: 'dashboard',
                    label: ln['dashboard'],
                    class: 'text-center',
                  },
                  {
                    key: 'chart',
                    label: ln['form_charttype'],
                    class: 'text-center',
                  },
                  {
                    key: 'size',
                    label: ln['size'],
                    class: 'text-center',
                  },
                  {
                    key: 'pin',
                    label: ln['status'],
                    class: 'text-center',
                  },
                  {
                    key: 'action',
                    label: ln['form_action'],
                    thClass: 'pr-4 text-right',
                    tdClass: 'pr-4 text-right',
                  },
                ]"
                :per-page="monitore_table.per_page"
                :current-page="monitore_table.cur_page"
              >
                <template v-slot:cell(dashboard)="row">{{
                  (row.item.dashboard && row.item.dashboard.name) || ''
                }}</template>

                <template v-slot:cell(chart)="row">
                  {{ row.item.chart ? row.item.chart.name : '-' }}
                </template>

                <template v-slot:cell(pin)="row">
                  <b-button
                    size="xs"
                    :variant="row.item.status ? 'success' : 'danger'"
                    class="px-2 font-weight-normal"
                    @click="$store.dispatch('toggleMonitorePin', row.item)"
                  >
                    {{ ln['form_' + (row.item.pin ? 'pin' : 'unpin')] }}
                  </b-button>
                </template>

                <template v-slot:cell(action)="row">
                  <b-button size="xs" variant="info" class="ml-2" @click="editMonitore(row.item)">
                    <fa-icons icon="edit" />
                  </b-button>
                  <b-button size="xs" variant="danger" class="ml-2" @click="deleteMonitore(row.item)">
                    <fa-icons icon="trash" />
                  </b-button>
                </template>
              </b-table>
            </div>

            <footer class="pb-1">
              <b-pagination
                v-show="show_monitore_pager"
                v-model="monitore_table.cur_page"
                :total-rows="monitore_len"
                :per-page="monitore_table.per_page"
                align="center"
              ></b-pagination>
              <p class="text-center text-info" v-show="!monitore_len">
                {{ ln['msg_empty'] }}
              </p>
            </footer>
          </section>
        </div>

        <div class="col-12 col-xl-12 order-first order-xl-first" v-if="show_monitore_form">
          <section class="widget pb-0">
            <header>
              <h4>
                {{ ln['monitore_' + (monitore_edit ? 'edit' : 'new')] }}
              </h4>
              <div class="widget-controls">
                <a href @click.prevent="toggleMonitoreForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <b-form @submit.prevent="submitMonitore()">
                <b-form-group :label="ln['form_monitorename']">
                  <b-form-input
                    v-model="monitore_data.name"
                    required
                    placeholder="e.g Sensor Graph"
                  ></b-form-input>
                </b-form-group>

                <b-form-group :label="ln['dashboard']">
                  <b-form-select
                    v-model="monitore_data.dashboard.dashboard_id"
                    :options="$store.state.dashboard"
                    value-field="dashboard_id"
                    text-field="name"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>
                        {{ ln['form_selectone'] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_charttype']">
                  <b-form-select
                    v-model="monitore_data.chart.chart_id"
                    :options="arr_chart"
                    value-field="chart_id"
                    text-field="name"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null">
                        {{ ln['form_selectnone'] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['form_chartsize']">
                  <b-form-select v-model="monitore_data.size" :options="[1, 2, 3, 4]" required>
                    <template v-slot:first>
                      <b-form-select-option :value="null">
                        {{ ln['form_selectnone'] }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group :label="ln['result'] || '-Query Filter'">
                  <b-card-group>
                    <b-card title="Filter by company">
                      <b-form-checkbox-group
                        class="col"
                        v-model="filter_company"
                        :options="arr_branch_company"
                        value-field="company_id"
                        text-field="name"
                        stacked
                      ></b-form-checkbox-group>
                    </b-card>
                    <b-card title="Filter by location">
                      <b-form-checkbox-group
                        class="col"
                        v-model="filter_location"
                        :options="arr_branch_location"
                        value-field="location_id"
                        text-field="name"
                        stacked
                      ></b-form-checkbox-group>
                    </b-card>
                    <b-card title="Filter by gateway">
                      <b-form-checkbox-group
                        class="col"
                        v-model="filter_gateway"
                        :options="arr_branch_gateway"
                        value-field="gateway_id"
                        text-field="name"
                        stacked
                      ></b-form-checkbox-group>
                    </b-card>
                    <b-card title="Filter by device">
                      <b-form-checkbox-group
                        class="col"
                        v-model="filter_device"
                        :options="arr_branch"
                        value-field="device_id"
                        text-field="name"
                        stacked
                      ></b-form-checkbox-group>
                    </b-card>
                  </b-card-group>
                </b-form-group>

                <b-form-group v-if="false">
                  <b-card-group>
                    <b-card title="company" bg-variant="dark" text-variant="white">
                      <b-card-text title="company" class="bg-dark text-light txtjson">
                        <pre class="text-white">{{ arr_branch_company }}</pre>
                      </b-card-text>
                    </b-card>
                    <b-card title="location" bg-variant="dark" text-variant="white">
                      <b-card-text class="bg-dark text-light txtjson">
                        <pre class="text-white">{{ arr_branch_location }}</pre>
                      </b-card-text>
                    </b-card>
                    <b-card title="gateway" bg-variant="dark" text-variant="white">
                      <b-card-text title="company" class="bg-dark text-light txtjson">
                        <pre class="text-white">{{ arr_branch_gateway }}</pre>
                      </b-card-text>
                    </b-card>
                    <b-card title="device" bg-variant="dark" text-variant="white">
                      <b-card-text class="bg-dark text-light txtjson">
                        <pre class="text-white">{{ arr_branch }}</pre>
                      </b-card-text>
                    </b-card>
                    <b-card title="branch" bg-variant="info" text-variant="white">
                      <b-card-text class="txtjson">
                        <pre class="text-white">{{ filter_device.map((branch) => `device-${branch}`) }}</pre>
                      </b-card-text>
                    </b-card>
                  </b-card-group>
                </b-form-group>

                <b-form-group>
                  <div class="row justify-content-md-center mt-3">
                    <div
                      class="col-12 mb-5"
                      v-if="monitore_data.chart.chart_id && monitore_data.chart.chart_id == 1"
                    >
                      <WidgetOnoff2 :watched_source="this.query_result" />
                    </div>
                    <div
                      class="col-12 mb-5"
                      v-if="monitore_data.chart.chart_id && monitore_data.chart.chart_id == 2"
                    >
                      <WidgetDonut2 :watched_source="this.query_result" />
                    </div>
                    <div
                      class="col-12 mb-5"
                      v-if="monitore_data.chart.chart_id && monitore_data.chart.chart_id == 3"
                    >
                      <WidgetProgress2 :watched_source="this.query_result" />
                    </div>
                    <div
                      class="col-12 mb-5"
                      v-if="monitore_data.chart.chart_id && monitore_data.chart.chart_id == 4"
                    >
                      <WidgetLine3 :watched_source="this.query_result" />
                    </div>
                    <div
                      class="col-12 mb-5"
                      v-if="monitore_data.chart.chart_id && monitore_data.chart.chart_id == 6"
                    >
                      <WidgetBars2 :watched_source="this.query_result" />
                    </div>
                    <div class="col-12 mb-5" v-if="monitore_data.chart.chart_id == null">
                      <WidgetDefault2 :watched_source="this.query_result" />
                    </div>
                  </div>
                  <!--
                  <b-card title="company" bg-variant="dark" text-variant="white">
                    <b-card-text title="company" class="bg-dark text-light txtjson">
                      <pre class="text-white">{{ query_result }}</pre>
                    </b-card-text>
                  </b-card>
                  -->
                </b-form-group>

                <b-button type="submit" variant="dark">
                  {{ ln['btn_' + (monitore_edit ? 'update' : 'create')] }}
                </b-button>
                <b-button @click="run_query" variant="success" class="ml-2">
                  <fa-icons icon="play" /> Run
                </b-button>
              </b-form>
            </div>
          </section>
        </div>

        <div class="col-12 col-xl-12 order-first order-xl-first" v-if="show_watch_form">
          <section class="widget text-center pb-0">
            <header>
              <h4>
                {{ ln['source_watch'] }}
              </h4>
              <h5>
                <small class="text-danger">{{
                  `${this.watched_source.source.device.name} - ${this.watched_source.source.name}`
                }}</small>
              </h5>
              <div class="widget-controls">
                <a href @click.prevent="closeWatchForm" class="text-dark">
                  <fa-icons icon="times" />
                </a>
              </div>
            </header>
            <div class="widget-body pb-4">
              <!-- 1: on/off -->
              <div v-if="this.watched_source.chart && this.watched_source.chart.chart_id == 1">
                <WidgetOnoff :watched_source="this.watched_source" />
              </div>

              <!-- 2: donut -->
              <div v-if="this.watched_source.chart && this.watched_source.chart.chart_id == 2">
                <div class="row justify-content-md-center">
                  <div class="col-12 col-xl-4">
                    <WidgetDonut :watched_source="this.watched_source" />
                  </div>
                </div>
              </div>

              <!-- 3: progress -->
              <div v-if="this.watched_source.chart && this.watched_source.chart.chart_id == 3">
                <div class="row justify-content-md-center">
                  <div class="col-12 col-xl-4">
                    <WidgetProgress :watched_source="this.watched_source" />
                  </div>
                </div>
              </div>

              <!-- 4: line -->
              <div v-if="this.watched_source.chart && this.watched_source.chart.chart_id == 4">
                <div class="row justify-content-md-center mt-3">
                  <div class="col-12 col-xl-6">
                    <WidgetLine :watched_source="this.watched_source" />
                  </div>
                </div>
              </div>

              <!-- 5: perc -->
              <div v-if="this.watched_source.chart && this.watched_source.chart.chart_id == 5">
                <WidgetPercentage :watched_source="this.watched_source" />
              </div>

              <!-- 6: bar -->
              <div v-if="this.watched_source.chart && this.watched_source.chart.chart_id == 6">
                <div class="row justify-content-md-center">
                  <div class="col-12 col-xl-6">
                    <WidgetBars :watched_source="this.watched_source" />
                  </div>
                </div>
              </div>

              <!-- 7: liquid -->
              <div v-if="this.watched_source.chart && this.watched_source.chart.chart_id == 7">
                <div class="row justify-content-md-center">
                  <div class="col-12 col-xl-4">
                    <WidgetLiquid :watched_source="this.watched_source" />
                  </div>
                </div>
              </div>

              <!-- 8: speed -->
              <div v-if="this.watched_source.chart && this.watched_source.chart.chart_id == 8">
                <div class="row justify-content-md-center">
                  <div class="col-12 col-xl-6">
                    <WidgetSpeed :watched_source="this.watched_source" />
                  </div>
                </div>
              </div>

              <!-- default -->
              <div v-if="!this.watched_source.chart">
                <WidgetDefault :watched_source="this.watched_source" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>
