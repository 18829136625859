<script>
//import d3 from 'https://cdnjs.cloudflare.com/ajax/libs/d3/3.5.5/d3.min.js'
import { renderChart } from '../liqu'

export default {
  props: ['watched_source'],
  components: {},
  data() {
    return {
      watch_timer: null,
      watch_data: {
        data: [
          {
            key: '',
            value: '',
            time: '',
          },
        ],
      },
      watched_config: '',
      chart_data: null,
    }
  },
  methods: {
    fillChart() {
      this.chart_data = JSON.parse(this.watch_data[0])
      this.setChartValue(this.chart_data.value)
    },
    watchHandler(_monitore) {
      /*
      this.$store.dispatch('monitorValue', _monitore).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res);
          this.watch_data = res.data[0].values
          this.fillChart()
        }
      })
      */
      let req = JSON.parse(_monitore.data_value_json)
      this.$store.dispatch('getKeyValue', req).then((res) => {
        if (res && res.err) {
          //this.showMsg(this.ln["msg_error"], "danger");
        } else {
          //console.log(res.data)
          this.watch_data = res.data
          this.fillChart()
        }
      })
    },
    beginWatch(_monitore) {
      this.endWatch()

      /*
      let _interval = _monitore.source.device.gateway.pull_interval
      if (_monitore.source.pull_interval) {
        _interval = _monitore.source.pull_interval
      } else if (_monitore.source.device.pull_interval) {
        _interval = _monitore.source.device.pull_interval
      }
      */
      let _interval = this.interval

      console.log(`watching every ${_interval} ms`)

      this.watchHandler(_monitore)
      if (_interval > 0) {
        this.watch_timer = setInterval(() => {
          this.watchHandler(_monitore)
        }, _interval)
      }
    },
    endWatch() {
      console.log(`End watching..`)
      clearInterval(this.watch_timer)
      this.watch_timer = null
    },
    setChartValue(num) {
      let colors = ['#2196f3', '#8bc34a', '#f44336']
      let cur_color = colors[1]
      let value = parseFloat(num)
      let cmin =
        Math.round((this.watched_config.max - this.watched_config.min) / 4) +
        Math.round(this.watched_config.min)
      let cmax =
        Math.round(this.watched_config.max) -
        Math.round((this.watched_config.max - this.watched_config.min) / 4)
      if (value < cmin) {
        cur_color = colors[0]
      } else if (value > cmax) {
        cur_color = colors[2]
      }
      //let perc = ((value - this.watched_config.min) * 100) / (this.watched_config.max - this.watched_config.min);
      //renderChart(this.$refs[`chart${this.watched_source.source.source_id}`], perc, cur_color);
      renderChart(
        this.$refs[`chart${this.watched_source.source.source_id}`],
        value,
        this.watched_config.min,
        this.watched_config.max,
        cur_color,
        this.watched_source.source.source_id
      )
    },
  },
  watch: {
    //eslint-disable-next-line
    watched_source: function(newVal, oldVal) {
      this.watch_data = newVal
      this.fillChart()
    },
    interval: function() {
      this.beginWatch(this.watched_source)
    },
  },
  mounted() {
    //console.log(this.watched_source);
    if (this.watched_source.data_value_json) {
      this.watched_config = JSON.parse(this.watched_source.data_value_json)
    }
    this.beginWatch(this.watched_source)

    //--

    //renderChart(this.$refs.chart1, 67.79)
    //this.setChartValue(67.79)
  },
  computed: {},
  destroyed() {
    this.endWatch()
  },
}
</script>

<template>
  <div class="row no-gutters justify-content-center">
    <div class="col-md-* p-2" v-for="sensor in this.chart_data" :key="sensor.label">
      <div :ref="`chart${sensor.label}`" class="p-4"></div>
      <h6 class="mt-3">
        <small>
          <fa-icons icon="clock" />
          {{ sensor.data ? frmDate(sensor.data[0].x) : '-' }}
        </small>
      </h6>
    </div>
  </div>
</template>
